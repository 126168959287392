<template>
  <b-modal id="modal-analysis" modal-class="fullscreen" content-class="bg-dark-navy rounded-0 border-0" header-class="bg-navy rounded-0 border-0" body-class="scroll-overflow no-x-scrolling no-border-radius pt-3" scrollable hide-footer>
    <template v-slot:modal-header="{ }">
      <b-row class="w-100">
        <b-col>
          <b-media no-body>
            <b-media-aside class="border-right mr-3 pr-3">
              <img :src="require('@/assets/images/logo-icon.svg')" class="m-auto d-block img-fluid" style="max-width:60px" />
            </b-media-aside>
            <b-media-body class="align-self-center">
              <h6 class="color-white">{{ current.submission.patient.demographics.name || current.submission.patient.identifier }}</h6>
              <b-badge pill class="indicator px-2" :class="getIndicator(current.analysis.conclusion.toLowerCase())">
                <font-awesome-icon :icon="getIcon(current.analysis.conclusion.toLowerCase())"></font-awesome-icon> {{ getConclusion(current.analysis.conclusion.toLowerCase()) }}
              </b-badge>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col class="align-self-center text-right pr-0">
          <b-button size="sm" variant="link" class="color-white font-weight-400 p-0" @click="justExitScreen()">
            Exit Analysis &amp; Report<i class="fas fa-times-circle color-white font-size-18 ml-2"></i>
          </b-button>
        </b-col>
      </b-row>
    </template>
    <b-row class="pb-3">
      <b-col class="px-2" cols="7">
        <div class="zoom-container mb-3" ref="labeller">
          <div :style="labellerStyle" class="mx-auto" :key="labellerKey">
            <b-alert class="float-alert" :show="activeImage !== 'current'" variant="danger">
              <b>Please, note:</b> You're viewing a historical image for this patient. Report details below apply to the current study / image.
            </b-alert>
            <img class="fluid" :src="selectedImage" :style="'width: 100%; filter: brightness(' + brightness + '%) contrast(' + contrast + '%) saturate(' + saturate + '%) sepia(' + sepia + '%) hue-rotate(' + hue + 'deg) invert(' + invert + '%);'" v-show="activeImage !== 'current'">
            <vue-cropper
              ref="cropper"
              :src="''"
              alt="Original Image"
              :auto-crop-area="0.5"
              :cropmove="cropImage"
              :responsive="true"
              :movable="false"
              :zoomable="false"
              :crossorigin="'*'"
              class="mt-3"
              :ready="onReady"
              :style="'filter: brightness(' + brightness + '%) contrast(' + contrast + '%) saturate(' + saturate + '%) sepia(' + sepia + '%) hue-rotate(' + hue + 'deg) invert(' + invert + '%);'"
              v-show="activeImage === 'current'"
            ></vue-cropper>
            <bounded-image :w="dimensions.w" :h="dimensions.h" :l="canvasLeft" v-if="loaded && activeImage === 'current' && !newPathology.active" :key="updated">
              <bounded-box
                v-for="(pathology, index) in filteredShownList"
                :key="index"
                :x="pathology.bounding_box.x * dimensions.ratio"
                :y="pathology.bounding_box.y * dimensions.ratio"
                :w="pathology.bounding_box.w * dimensions.ratio"
                :h="pathology.bounding_box.h * dimensions.ratio"
                :pathology="$options.filters.formatPathologyName(pathology.predicted_diagnosis, options)"
                :confidence="pathology.confidence"
                :colour="pathology.colour"
              ></bounded-box>
              <bounded-box-ctr
                v-if="hasCardiomegaly(current.submission) !== false && shownList.ctr === true"
                :key="filteredList.length"
                :x="cardioList.find(_pathology => _pathology.predicted_diagnosis === 'Cardio').bounding_box.x * dimensions.ratio"
                :y="cardioList.find(_pathology => _pathology.predicted_diagnosis === 'Cardio').bounding_box.y * dimensions.ratio"
                :w="cardioList.find(_pathology => _pathology.predicted_diagnosis === 'Cardio').bounding_box.w * dimensions.ratio"
                pathology="Cardio"
                :ratio="cardioList.find(_pathology => _pathology.predicted_diagnosis === 'Cardio').confidence"
                :colour="cardioList.find(_pathology => _pathology.predicted_diagnosis === 'Cardio').colour"
              ></bounded-box-ctr>
              <bounded-box-ctr
                v-if="hasCardiomegaly(current.submission) !== false && shownList.ctr === true"
                :key="filteredList.length + 1"
                :x="cardioList.find(_pathology => _pathology.predicted_diagnosis === 'Thoracic').bounding_box.x * dimensions.ratio"
                :y="cardioList.find(_pathology => _pathology.predicted_diagnosis === 'Thoracic').bounding_box.y * dimensions.ratio"
                :w="cardioList.find(_pathology => _pathology.predicted_diagnosis === 'Thoracic').bounding_box.w * dimensions.ratio"
                pathology="Thoracic"
                :ratio="cardioList.find(_pathology => _pathology.predicted_diagnosis === 'Thoracic').confidence"
                :colour="cardioList.find(_pathology => _pathology.predicted_diagnosis === 'Thoracic').colour"
              ></bounded-box-ctr>
            </bounded-image>
          </div>
        </div>
      </b-col>
      <b-col class="px-2" cols="1" v-if="open.previous">
        <b-row>
          <b-col>
            <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 mr-3" @click="toggle('previous')">
              <font-awesome-icon icon="poll" class="mr-2"></font-awesome-icon> Studies
            </b-button>
          </b-col>
        </b-row>
        <hr class="border-blue mt-1" />
        <div class="studies-scroller px-2" :style="dimensions.h ? 'height: ' + (dimensions.h - 57) + 'px;' : null">
          <ul class="list-unstyled row mx-n1 heatmap-list" :key="updatedOthers">
            <li class="col-12 px-1 mb-1">
              <a :class="activeImage === 'current' ? 'active' : ''" href="#" @click.prevent="activeImage = 'current'">
                <img :src="apiRoute(submission.attachments[0].path) + submission.attachments[0].path" class="img-fluid" />
              </a>
              <p class="text-center mb-0" :class="activeImage === 'current' ? 'text-warning' : ''"><small>Current</small></p>
            </li>
            <li class="col-12 px-1 mb-1" v-for="submission in filteredOthersList" :key="submission.submission._id">
              <a :class="activeImage === submission.submission._id + '|original' ? 'active' : ''" href="#" @click.prevent="activeImage = submission.submission._id + '|original'" v-if="submission.submission.attachments && submission.submission.attachments.length > 0">
                <img :src="apiRoute(submission.submission.attachments[0].path) + submission.submission.attachments[0].path" class="img-fluid" />
              </a>
              <a class="mt-1" :class="activeImage === submission.submission._id + '|bounded' ? 'active' : ''" href="#" @click.prevent="activeImage = submission.submission._id + '|bounded'" v-if="submission.submission.attachments && submission.submission.attachments.length > 0 && submission.submission.attachments[0].response && submission.submission.attachments[0].response.bounding">
                <img :src="apiRoute(submission.submission.attachments[0].response.bounding.path) + submission.submission.attachments[0].response.bounding.path" class="img-fluid" />
              </a>
              <p class="text-center mb-1" :class="activeImage.indexOf(submission.submission._id) >= 0 ? 'text-warning' : ''">
                <small>{{ formatDateNoTime(submission.submission.created_at) }}</small><br />
                <b-badge pill class="indicator px-2" :class="getIndicator(submission.analysis.conclusion.toLowerCase())" v-if="submission.analysis && submission.analysis.conclusion">
                  <font-awesome-icon class="mr-1" :icon="getIcon(submission.analysis.conclusion.toLowerCase())"></font-awesome-icon> Score: <b class="font-up">{{ (submission.analysis.score).toFixed(2) }}</b>
                </b-badge>
              </p>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col class="px-2" :cols="open.previous ? '4' : '5'">
        <b-row>
          <b-col cols="3" v-if="!open.previous">
            <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 mr-3" @click="toggle('previous')">
              <font-awesome-icon icon="poll" class="mr-2"></font-awesome-icon> Studies
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 mr-3" @click="toggle('feedback')" :disabled="activeImage !== 'current' || (_report.info && _report.info.status === 'Completed')">
              Detections Analysis <font-awesome-icon icon="comment-medical" class="ml-2"></font-awesome-icon>
            </b-button>
            <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 mr-3" @click="toggle('image')">
              Enhance Image <font-awesome-icon icon="adjust" class="ml-2"></font-awesome-icon>
            </b-button>
            <b-button size="sm" variant="link" class="color-white font-weight-400 p-0" @click="toggle('patient')">
              Patient Details <font-awesome-icon icon="user-injured" class="ml-2"></font-awesome-icon>
            </b-button>
          </b-col>
        </b-row>
        <hr class="border-blue mt-1" />
        <b-alert :show="activeImage !== 'current'" variant="danger">
          <b>Please, note:</b> You're viewing a historical image for this patient. Report details below apply to the current study / image.
        </b-alert>
        <b-alert :show="Boolean(alert.message)" :variant="alert.type ? alert.type.replace('alert-', '') : null" :key="alert.counter">
          {{ alert.message }}
        </b-alert>
        <h6 class="color-white mb-2">TECHNICAL QUALITY</h6>
        <div class="row" v-for="row in report.technical" v-bind:key="row.view">
          <div class="col-sm-5">
            <label for="form_quality">Technical quality *</label>
            <b-form-select :disabled="_report.info && _report.info.status === 'Completed'" id="form_quality" variant="dark" v-model="report.technical[0].quality" name="quality">
              <option>Acceptable</option>
              <option>Poor, but readable</option>
              <option>Not acceptable</option>
              <option>Not readable</option>
            </b-form-select>
            <div class="help-block with-errors"></div>
          </div>
          <div class="col-sm-5" v-if="report.technical[0].quality === 'Not readable' || report.technical[0].quality === 'Not acceptable'">
            <div class="form-group mb-0">
              <label for="form_resultType">{{ report.technical[0].quality }} reason *</label>
              <b-form-input v-model="report.technical[0].reason" type="text" name="qualityReason" placeholder="Reason for technical quality selection" />
            </div>
          </div>
        </div>
        <hr class="border-blue" />
        <b-row>
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table table-borderless table-sm color-white mb-0" width="100%">
                <thead>
                  <tr class="border-bottom border-white">
                    <th width="180" class="pl-0 font-size-16">PARENCHYMA</th>
                    <th class="text-center pl-0">RT</th>
                    <th class="text-center pl-0">LT</th>
                    <th class="text-center pl-0">RUZ</th>
                    <th class="text-center pl-0">RMZ</th>
                    <th class="text-center pl-0">RLZ</th>
                    <th class="text-center pl-0">LUZ</th>
                    <th class="text-center pl-0">LMZ</th>
                    <th class="text-center pl-0">LLZ</th>
                  </tr>
                </thead>
                <tbody :key="updatedPathology">
                  <tr v-for="pathology in sortedParenchema" :key="current.submission._id + '_' + pathology.name">
                    <td>
                      <font-awesome-icon :icon="pathology.rt || pathology.lt ? 'check-circle' : 'times-circle'" class="color-yellow mr-2 font-size-18"></font-awesome-icon>
                      {{ pathology.name | formatPathologyName(options) }}
                    </td>
                    <td class="text-center pl-0">
                      <b-button class="py-0 border-0" variant="link" :disabled="true">
                        <font-awesome-icon :icon="pathology.rt ? 'check-square' : 'square'" class="color-white ml-1 font-size-14" :class="{'color-yellow': pathology.rt}"></font-awesome-icon>
                      </b-button>
                    </td>
                    <td class="text-center pl-0">
                      <b-button class="py-0 border-0" variant="link" :disabled="true">
                        <font-awesome-icon :icon="pathology.lt ? 'check-square' : 'square'" class="color-white ml-1 font-size-14" :class="{'color-yellow': pathology.lt}"></font-awesome-icon>
                      </b-button>
                    </td>
                    <td class="text-center pl-0">
                      <b-button class="py-0 border-0" variant="link" :disabled="_report.info && _report.info.status === 'Completed'" href="#" @click.prevent="toggleReportPathology(pathology.name, 'ruz')">
                        <font-awesome-icon :icon="pathology.ruz ? 'check-square' : 'square'" class="color-white ml-1 font-size-14" :class="{'color-yellow': pathology.ruz}"></font-awesome-icon>
                      </b-button>
                    </td>
                    <td class="text-center pl-0">
                      <b-button class="py-0 border-0" variant="link" :disabled="_report.info && _report.info.status === 'Completed'" href="#" @click.prevent="toggleReportPathology(pathology.name, 'rmz')">
                        <font-awesome-icon :icon="pathology.rmz ? 'check-square' : 'square'" class="color-white ml-1 font-size-14" :class="{'color-yellow': pathology.rmz}"></font-awesome-icon>
                      </b-button>
                    </td>
                    <td class="text-center pl-0">
                      <b-button class="py-0 border-0" variant="link" :disabled="_report.info && _report.info.status === 'Completed'" href="#" @click.prevent="toggleReportPathology(pathology.name, 'rlz')">
                        <font-awesome-icon :icon="pathology.rlz ? 'check-square' : 'square'" class="color-white ml-1 font-size-14" :class="{'color-yellow': pathology.rlz}"></font-awesome-icon>
                      </b-button>
                    </td>
                    <td class="text-center pl-0">
                      <b-button class="py-0 border-0" variant="link" :disabled="_report.info && _report.info.status === 'Completed'" href="#" @click.prevent="toggleReportPathology(pathology.name, 'luz')">
                        <font-awesome-icon :icon="pathology.luz ? 'check-square' : 'square'" class="color-white ml-1 font-size-14" :class="{'color-yellow': pathology.luz}"></font-awesome-icon>
                      </b-button>
                    </td>
                    <td class="text-center pl-0">
                      <b-button class="py-0 border-0" variant="link" :disabled="_report.info && _report.info.status === 'Completed'" href="#" @click.prevent="toggleReportPathology(pathology.name, 'lmz')">
                        <font-awesome-icon :icon="pathology.lmz ? 'check-square' : 'square'" class="color-white ml-1 font-size-14" :class="{'color-yellow': pathology.lmz}"></font-awesome-icon>
                      </b-button>
                    </td>
                    <td class="text-center pl-0">
                      <b-button class="py-0 border-0" variant="link" :disabled="_report.info && _report.info.status === 'Completed'" href="#" @click.prevent="toggleReportPathology(pathology.name, 'llz')">
                        <font-awesome-icon :icon="pathology.llz ? 'check-square' : 'square'" class="color-white ml-1 font-size-14" :class="{'color-yellow': pathology.llz}"></font-awesome-icon>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row>
              <b-col cols="5">
                <div class="form-group">
                  <label for="form_resultType">Add pathology</label>
                  <b-form-select :disabled="_report.info && _report.info.status === 'Completed'" v-model="report.other" :options="filteredOptions.map(_option => { return { value: _option, text: _option } })">
                    <template #first>
                      <b-form-select-option :value="null" disabled>Please select a pathology to add</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </b-col>
              <b-col cols="5" v-if="report.other === 'Other'">
                <div class="form-group">
                  <label for="form_resultType">Other pathology name *</label>
                  <b-form-input v-model="report.other_name" type="text" name="otherPathologyName" placeholder="Other pathology name" />
                </div>
              </b-col>
              <b-col cols="2">
                <p class="mb-2">&nbsp;</p>
                <input :disabled="!report.other || (report.other === 'Other' && !report.other_name)" type="button" @click.prevent="addPathology()" class="btn btn-primary btn-send btn-sm rounded-pill px-3 mt-1" value="Add" />
              </b-col>
            </b-row>
          </div>
        </b-row>
        <hr class="border-blue mt-0" />
        <h6 class="color-white mb-3">CONCLUSION</h6>
        <b-row>
          <div class="col-sm-12">
            <div class="form-group mb-2">
              <b-form-textarea :disabled="_report.info && _report.info.status === 'Completed'" id="form_conclusion" v-model="report.conclusion" rows="5" name="conclusion" size="sm"></b-form-textarea>
            </div>
          </div>
        </b-row>
        <b-row class="mb-2">
          <div class="col-sm-8">
            <div class="form-group mb-0">
              <label for="form_investigatorCode">Report completed by</label>
              <b-form-input :disabled="true" v-model="report.reporter.investigatorCode" type="text" id="form_investigatorCode" name="investigatorCode" placeholder="Investigator Code" />
            </div>
          </div>
        </b-row>
        <hr class="border-blue" />
        <b-row>
          <div class="col-sm-5">
            <div class="form-group">
              <label for="form_status">Status *</label>
              <b-form-select :disabled="_report.info && _report.info.status === 'Completed'" id="form_status" variant="dark" v-model="report.status" name="status">
                <option>In Progress</option>
                <option>Completed</option>
              </b-form-select>
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="form_baseline">Baseline *</label>
              <b-form-select :disabled="_report.info && _report.info.status === 'Completed'" id="form_baseline" v-model="report.baseline" name="baseline">
                <option>Yes</option>
                <option>No</option>
              </b-form-select>
              <div class="help-block with-errors"></div>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="form-group">
              <label for="form_resultType">Result Type *</label>
              <b-form-select :disabled="_report.info && _report.info.status === 'Completed'" id="form_resultType" v-model="report.resultType" name="resultType">
                <option>Normal</option>
                <option>Abnormal</option>
              </b-form-select>
              <div class="help-block with-errors"></div>
            </div>
          </div>
        </b-row>
        <b-row>
          <div class="col-sm-5">
            <input :disabled="_report.status.saving || _report.info && _report.info.status === 'Completed'" type="button" @click.prevent="toggle('ilo')" class="btn btn-success btn-send btn-sm rounded-pill px-3" value="ILO Reporting">
          </div>
          <div class="col-sm-7">
            <input :disabled="_report.status.saving || _report.info && _report.info.status === 'Completed'" type="button" @click.prevent="submitAndComplete()" class="btn btn-success btn-send float-right btn-sm rounded-pill px-3" value="Save & Complete">
            <input :disabled="_report.status.saving || _report.info && _report.info.status === 'Completed'" type="button" @click.prevent="submit()" class="btn btn-primary btn-send float-right btn-sm rounded-pill px-3 mr-2" value="Save Details">
            <input :disabled="(_report.info && _report.info.status !== 'Completed') || !(_report.info && _report.info.attachment && _report.info.attachment.path)" type="button" @click.prevent="downloadPdf()" class="btn btn-outline-primary btn-send float-right btn-sm rounded-pill px-3 mr-2" value="Download PDF">
          </div>
        </b-row>
      </b-col>
    </b-row>
    <div class="slidein bg-navy p-3 mt-2" :class="open.feedback ? 'open' : ''">
      <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 float-right" @click="toggle('feedback')">
        <i class="fas fa-times-circle color-white font-size-18 ml-2"></i>
      </b-button>
      <div class="table-responsive">
        <table class="table table-borderless table-sm color-white mb-0" width="100%">
          <thead>
            <tr class="border-bottom border-blue">
              <th class="pl-0 font-size-18">Detections</th>
              <th class="text-right pl-0">Confidence<br /><br /></th>
              <th class="text-center pl-0" style="width: 8rem;">
                Show / Hide<br />
                <a href="#" @click.prevent="toggleAll(true)">
                  <font-awesome-icon icon="eye" class="color-yellow mr-1 font-size-14"></font-awesome-icon>
                </a> /
                <a href="#" @click.prevent="toggleAll(false)">
                  <font-awesome-icon icon="eye-slash" class="color-white ml-1 font-size-14"></font-awesome-icon>
                </a>
              </th>
              <th class="text-center pl-0" style="width: 8rem;">
                Concordance<br />
                <a href="#" @click.prevent="toggleConcordanceAll(true)">
                  <font-awesome-icon icon="check-square" class="color-yellow mr-1 font-size-14"></font-awesome-icon>
                </a> /
                <a href="#" @click.prevent="toggleConcordanceAll(false)">
                  <font-awesome-icon icon="square" class="color-white ml-1 font-size-14"></font-awesome-icon>
                </a>
              </th>
              <th class="text-center pl-0" style="width: 6rem;">
                Edit / Amend<br /><br />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pathology in filteredList" :key="pathology._id">
              <td>
                <font-awesome-icon icon="check-circle" class="mr-2 font-size-18" :style="{ color: pathology.colour }"></font-awesome-icon>
                <span class="font-size-16">{{ pathology.predicted_diagnosis | formatPathologyName(options) }}</span>
              </td>
              <td class="text-right" v-if="pathology.confidence">
                <span class="color-white font-size-14">{{ (Math.round(pathology.confidence * 10000) / 100).toFixed(2) }}%</span>
                <font-awesome-icon icon="microchip" class="color-yellow ml-2 font-size-14"></font-awesome-icon>
              </td>
              <td class="text-right" v-else>
                <font-awesome-icon icon="plus" class="color-blue ml-2 font-size-14"></font-awesome-icon>
              </td>
              <td class="text-center">
                <a href="#" @click.prevent="toggleShown(pathology._id)" :key="updated" v-if="isConcordance(pathology._id) !== false">
                  <font-awesome-icon icon="eye" class="color-yellow ml-2 font-size-14" v-if="isShown(pathology._id) === false"></font-awesome-icon>
                  <font-awesome-icon icon="eye-slash" class="color-white ml-2 font-size-14" v-else></font-awesome-icon>
                </a>
              </td>
              <td class="text-center" v-if="pathology.confidence">
                <a href="#" @click.prevent="toggleConcordance(pathology._id)" :key="updated">
                  <font-awesome-icon icon="square" class="color-white ml-2 font-size-14" v-if="isConcordance(pathology._id) === false"></font-awesome-icon>
                  <font-awesome-icon icon="check-square" class="color-yellow ml-2 font-size-14" v-else></font-awesome-icon>
                </a>
              </td>
              <td class="text-center" v-else>
                <a href="#" @click.prevent="deleteOther(pathology._id)" :key="updated">
                  <font-awesome-icon icon="trash" class="color-white ml-2 font-size-14"></font-awesome-icon>
                </a>
              </td>
              <td class="text-center">
                <a href="#" @click.prevent="toggleEdit(pathology._id)" :key="updated" v-if="!newPathology.active">
                  <font-awesome-icon icon="edit" class="color-white ml-2 font-size-14"></font-awesome-icon>
                </a>
                <a href="#" @click.prevent="saveEdit()" v-if="newPathology.active && newPathology.index === pathology._id">
                  <font-awesome-icon icon="check" class="color-blue ml-2 font-size-14"></font-awesome-icon>
                </a>
                <a href="#" @click.prevent="cancelEdit()" v-if="newPathology.active && newPathology.index === pathology._id">
                  <font-awesome-icon icon="times" class="color-yellow ml-2 font-size-14"></font-awesome-icon>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <font-awesome-icon icon="check-circle" class="mr-2 font-size-18" :style="{ color: colours[filteredList.length] }"></font-awesome-icon>
                <font-awesome-icon icon="check-circle" class="mr-2 font-size-18" :style="{ color: colours[filteredList.length + 1] }"></font-awesome-icon>
                <span class="font-size-16">Cardio Thoracic Ratio</span>
              </td>
              <td class="text-right">
                <span class="color-white font-size-14">{{ (Math.round(hasCardiomegaly(current.submission) * 10000) / 100).toFixed(2) }}%</span>
              </td>
              <td class="text-center" :key="updated">
                <a href="#" @click.prevent="toggleShown('ctr')">
                  <font-awesome-icon icon="eye" class="color-yellow ml-2 font-size-14" v-if="isShown('ctr') === false"></font-awesome-icon>
                  <font-awesome-icon icon="eye-slash" class="color-white ml-2 font-size-14" v-else></font-awesome-icon>
                </a>
              </td>
            </tr>
            <tr v-if="!newPathology.active || (newPathology.active && !newPathology.index)">
              <td colspan="3">
                <font-awesome-icon icon="check-circle" class="mr-2 font-size-18" :style="{ color: colours[filteredList.length + 2] }"></font-awesome-icon>
                <span class="font-size-16">Other pathologies</span><br />
                <b-row class="mt-2" v-if="isConcordance('other') === true">
                  <b-col cols="5">
                    <b-form-select :disabled="_report.info && _report.info.status === 'Completed'" v-model="newPathology.name" :options="options.map(_option => { return { value: _option, text: _option } })">
                      <template #first>
                        <b-form-select-option :value="null" disabled>Please select a pathology to add</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-col>
                  <b-col cols="5" v-if="newPathology.name === 'Other'">
                    <b-form-input v-model="newPathology.other" type="text" name="otherPathologyName" placeholder="Other pathology name" />
                  </b-col>
                  <b-col cols="2">
                    <input :disabled="!newPathology.name || (newPathology.name === 'Other' && !newPathology.other)" type="button" @click.prevent="addPathologyBox()" class="btn btn-primary btn-send btn-sm rounded-pill px-3 mt-1" :value="!newPathology.active ? 'Mark' : 'Save'" />
                  </b-col>
                </b-row>
              </td>
              <td class="text-center" :key="updated">
                <a href="#" @click.prevent="toggleConcordance('other')">
                  <font-awesome-icon icon="square" class="color-white ml-2 font-size-14" v-if="isConcordance('other') === false"></font-awesome-icon>
                  <font-awesome-icon icon="check-square" class="color-yellow ml-2 font-size-14" v-else></font-awesome-icon>
                </a>
              </td>
            </tr>
            <tr class="border-top border-blue">
              <td>
                <font-awesome-icon class="mr-2 font-size-18" :icon="getIcon(current.analysis.conclusion.toLowerCase())"></font-awesome-icon>
                <span class="font-size-16">{{ getConclusion(current.analysis.conclusion.toLowerCase()) }}</span><br />
              </td>
              <td class="text-right">
                <span class="color-white font-size-14">{{ (Math.round(current.analysis.score * 100) / 100).toFixed(2) }}</span>
                <font-awesome-icon icon="microchip" class="color-yellow ml-2 font-size-14"></font-awesome-icon>
              </td>
              <td class="text-center">&nbsp;</td>
              <td class="text-center" :key="updated">
                <a href="#" @click.prevent="diagnosisAgree = !diagnosisAgree">
                  <font-awesome-icon icon="square" class="color-white ml-2 font-size-14" v-if="!diagnosisAgree"></font-awesome-icon>
                  <font-awesome-icon icon="check-square" class="color-yellow ml-2 font-size-14" v-else></font-awesome-icon>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-row>
        <b-col md="6" offset-md="6">
          <input :disabled="newPathology.active" type="button" @click.prevent="exitScreen()" class="btn btn-primary btn-send float-right btn-sm rounded-pill px-3 mr-2" value="Save Analysis">
          <input type="button" @click.prevent="toggle('feedback')" class="btn btn-outline-primary btn-send float-right btn-sm rounded-pill px-3 mr-2" value="Close">
        </b-col>
      </b-row>
    </div>
    <div class="slidein bg-navy p-3 mt-2" :class="open.image ? 'open' : ''">
      <b-form>
        <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 float-right" @click="toggle('image')">
          <i class="fas fa-times-circle color-white font-size-18 ml-2"></i>
        </b-button>
        <h6 class="color-white mb-3">Enhance image</h6>
        <b-row>
          <b-col cols="2"><p class="mb-0 pt-2">Brightness</p></b-col>
          <b-col>
            <b-input-group>
              <template v-slot:prepend>
                <b-input-group-text class="bg-transparent border-0">
                  <i class="fas fa-sun color-white"></i>
                </b-input-group-text>
              </template>
              <b-form-input v-model="brightness" type="range" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2"><p class="mb-0 pt-2">Contrast</p></b-col>
          <b-col>
            <b-input-group>
              <template v-slot:prepend>
                <b-input-group-text class="bg-transparent border-0">
                  <i class="fas fa-sun color-white"></i>
                </b-input-group-text>
              </template>
              <b-form-input v-model="contrast" type="range" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2"><p class="mb-0 pt-2">Saturation</p></b-col>
          <b-col>
            <b-input-group>
              <template v-slot:prepend>
                <b-input-group-text class="bg-transparent border-0">
                  <i class="fas fa-sun color-white"></i>
                </b-input-group-text>
              </template>
              <b-form-input v-model="saturate" type="range" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2"><p class="mb-0 pt-2">Sepia</p></b-col>
          <b-col>
            <b-input-group>
              <template v-slot:prepend>
                <b-input-group-text class="bg-transparent border-0">
                  <i class="fas fa-sun color-white"></i>
                </b-input-group-text>
              </template>
              <b-form-input v-model="sepia" type="range" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="2"><p class="mb-0 pt-2">Invert</p></b-col>
          <b-col>
            <b-input-group>
              <template v-slot:prepend>
                <b-input-group-text class="bg-transparent border-0">
                  <i class="fas fa-sun color-white"></i>
                </b-input-group-text>
              </template>
              <b-form-input v-model="invert" type="range" min="0" max="200" class="bg-transparent border-0 outline-removed"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div class="slidein bg-navy p-3 mt-2" :class="open.patient ? 'open' : ''">
      <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 float-right" @click="toggle('patient')">
        <i class="fas fa-times-circle color-white font-size-18 ml-2"></i>
      </b-button>
      <h6 class="color-white">Patient Details</h6>
      <b-row>
        <b-col cols="3" class="text-muted">
          Created:
        </b-col>
        <b-col cols="7">
          {{ submission.patient ? formatDate(submission.patient.created_at) : '' }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" class="text-muted">
          Patient:
        </b-col>
        <b-col cols="7">
          {{ submission.patient ? submission.patient.identifier : 'Not found' }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" class="text-muted">
          Age Group:
        </b-col>
        <b-col cols="7">
          {{ submission.patient ? submission.patient.demographics.ageGroup : '' }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" class="text-muted">
          Gender:
        </b-col>
        <b-col cols="7">
          {{ submission.patient ? submission.patient.demographics.gender : '' }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" class="text-muted">
          Country:
        </b-col>
        <b-col cols="7">
          {{ submission.patient ? submission.patient.demographics.country : '' }}
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="3" class="text-muted">
          Province:
        </b-col>
        <b-col cols="7">
          {{ submission.patient ? submission.patient.demographics.province : '' }}
        </b-col>
      </b-row>
    </div>
    <div class="slidein slidein-ilo bg-navy p-3 mt-2" :class="open.ilo ? 'open' : ''">
      <b-button size="sm" variant="link" class="color-white font-weight-400 p-0 float-right" @click="toggle('ilo')">
        <i class="fas fa-times-circle color-white font-size-18 ml-2"></i>
      </b-button>
      <h6 class="color-white">Complete ILO (2000) Classification of Radiographs of Pneumoconioses</h6>
      <hr class="border-blue" />
      <b-row>
        <b-col cols="6">
          <div class="form-group">
            <label for="ilo_readerCode">Reader Code</label>
            <b-form-input :disabled="_report.info && _report.info.status === 'Completed'" id="ilo_readerCode" v-model="ilo.readerCode" name="readerCode">
            </b-form-input>
            <div class="help-block with-errors"></div>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label for="ilo_radiographIdentifier">Radiograph Identifier</label>
            <b-form-input :disabled="_report.info && _report.info.status === 'Completed'" id="ilo_radiographIdentifier" v-model="ilo.radiographIdentifier" name="radiographIdentifier">
            </b-form-input>
            <div class="help-block with-errors"></div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="form-group">
            <label for="ilo_dateOfReading">Date of Reading</label>
            <b-form-input :disabled="_report.info && _report.info.status === 'Completed'" id="ilo_dateOfReading" v-model="ilo.dateOfReading" name="dateOfReading">
            </b-form-input>
            <div class="help-block with-errors"></div>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label for="ilo_dateOfRadiograph">Date of Radiograph</label>
            <b-form-input :disabled="_report.info && _report.info.status === 'Completed'" id="ilo_dateOfRadiograph" v-model="ilo.dateOfRadiograph" name="dateOfRadiograph">
            </b-form-input>
            <div class="help-block with-errors"></div>
          </div>
        </b-col>
      </b-row>
      <hr class="border-blue" />
      <div class="d-flex flex-column" style="height: calc(100vh - 22.5rem);">
        <div class="flex-grow-1 overflow-y overflow-x-hidden mr--3 pr-3">
          <b-row>
            <b-col cols="6">
              <div class="form-group">
                <label for="ilo_technicalQuality">
                  <b>Technical Quality</b><br />
                  <small class="ml-2">Grade 1, 2, 3, or 4</small><br />
                  <small class="ml-2">If grade not 1, <b>Comment</b> required here.</small>
                </label>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="form-group float-right text-right">
                <p class="mb-2"><small>(Mark appropriate box)</small></p>
                <b-button-group>
                  <b-button :pressed="ilo.technicalQuality === '1'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.technicalQuality = '1'" :variant="ilo.technicalQuality === '1' ? 'primary-white' : 'outline-primary-white'">1</b-button>
                  <b-button :pressed="ilo.technicalQuality === '2'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.technicalQuality = '2'" :variant="ilo.technicalQuality === '2' ? 'primary-white' : 'outline-primary-white'">2</b-button>
                  <b-button :pressed="ilo.technicalQuality === '3'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.technicalQuality = '3'" :variant="ilo.technicalQuality === '3' ? 'primary-white' : 'outline-primary-white'">3</b-button>
                  <b-button :pressed="ilo.technicalQuality === '4'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.technicalQuality = '4'" :variant="ilo.technicalQuality === '4' ? 'primary-white' : 'outline-primary-white'">4</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="ilo.technicalQuality && ilo.technicalQuality !== '1'">
            <b-col cols="12">
              <div class="form-group">
                <label for="ilo_comment">Comment</label>
                <b-form-textarea id="ilo_comment" v-model="ilo.comment" name="comment"></b-form-textarea>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
          </b-row>
          <hr class="border-blue" />
          <h6 class="color-white mb-3">PARENCHYMAL ABNORMALITIES</h6>
          <b-row>
            <b-col cols="6">
              <div class="form-group">
                <label for="ilo_technicalQuality">
                  <b>Small opacities</b><br />
                  <small class="ml-2">Profusion (12-point scale)</small><br />
                  <small class="ml-2">0/- 0/0 0/1 1/0 1/1 1/2 2/1 2/2 2/3 3/2 3/3 3/+</small><br />
                  <small class="ml-2">(Consult standard radiographs - mark profusion subcategory.)</small>
                </label>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="form-group float-right text-right">
                <b-button-group class="d-flex flex-column">
                  <b-button-group class="mb-2">
                    <b-button :pressed="ilo.smallOpacities === '0/-'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacities = '0/-'" :variant="ilo.smallOpacities === '0/-' ? 'primary-white' : 'outline-primary-white'" class="rounded-left">0/-</b-button>
                    <b-button :pressed="ilo.smallOpacities === '0/0'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacities = '0/0'" :variant="ilo.smallOpacities === '0/0' ? 'primary-white' : 'outline-primary-white'">0/0</b-button>
                    <b-button :pressed="ilo.smallOpacities === '0/1'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacities = '0/1'" :variant="ilo.smallOpacities === '0/1' ? 'primary-white' : 'outline-primary-white'" class="rounded-right">0/1</b-button>
                  </b-button-group>
                  <b-button-group class="mb-2">
                    <b-button :pressed="ilo.smallOpacities === '1/0'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacities = '1/0'" :variant="ilo.smallOpacities === '1/0' ? 'primary-white' : 'outline-primary-white'" class="rounded-left">1/0</b-button>
                    <b-button :pressed="ilo.smallOpacities === '1/1'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacities = '1/1'" :variant="ilo.smallOpacities === '1/1' ? 'primary-white' : 'outline-primary-white'">1/1</b-button>
                    <b-button :pressed="ilo.smallOpacities === '1/2'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacities = '1/2'" :variant="ilo.smallOpacities === '1/2' ? 'primary-white' : 'outline-primary-white'" class="rounded-right">1/2</b-button>
                  </b-button-group>
                  <b-button-group class="mb-2">
                    <b-button :pressed="ilo.smallOpacities === '2/1'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacities = '2/1'" :variant="ilo.smallOpacities === '2/1' ? 'primary-white' : 'outline-primary-white'" class="rounded-left">2/1</b-button>
                    <b-button :pressed="ilo.smallOpacities === '2/2'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacities = '2/2'" :variant="ilo.smallOpacities === '2/2' ? 'primary-white' : 'outline-primary-white'">2/2</b-button>
                    <b-button :pressed="ilo.smallOpacities === '2/3'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacities = '2/3'" :variant="ilo.smallOpacities === '2/3' ? 'primary-white' : 'outline-primary-white'" class="rounded-right">2/3</b-button>
                  </b-button-group>
                  <b-button-group>
                    <b-button :pressed="ilo.smallOpacities === '3/2'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacities = '3/2'" :variant="ilo.smallOpacities === '3/2' ? 'primary-white' : 'outline-primary-white'" class="rounded-left">3/2</b-button>
                    <b-button :pressed="ilo.smallOpacities === '3/3'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacities = '3/3'" :variant="ilo.smallOpacities === '3/3' ? 'primary-white' : 'outline-primary-white'">3/3</b-button>
                    <b-button :pressed="ilo.smallOpacities === '3/+'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacities = '3/+'" :variant="ilo.smallOpacities === '3/+' ? 'primary-white' : 'outline-primary-white'" class="rounded-right">3/+</b-button>
                  </b-button-group>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <div class="form-group">
                <label for="ilo_technicalQuality">
                  <small class="ml-2">Affected zones</small><br />
                  <small class="ml-2">(Mark <b><u>ALL</u></b> affected zones)</small>
                </label>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="form-group float-right text-right">
                <b-button-group vertical>
                  <b-button-group class="mb-2">
                    <b-button disabled variant="outline-primary-white" class="rounded-left">R</b-button>
                    <b-button disabled variant="outline-primary-white" class="rounded-right">L</b-button>
                  </b-button-group>
                  <b-button-group class="mb-2">
                    <b-button :pressed="ilo.smallOpacitiesZones.includes('RUpper')" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSmallOpacitiesZone('RUpper')" :variant="ilo.smallOpacitiesZones.includes('RUpper') ? 'primary-white' : 'outline-primary-white'" class="rounded-left">Upper</b-button>
                    <b-button :pressed="ilo.smallOpacitiesZones.includes('LUpper')" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSmallOpacitiesZone('LUpper')" :variant="ilo.smallOpacitiesZones.includes('LUpper') ? 'primary-white' : 'outline-primary-white'" class="rounded-right">Upper</b-button>
                  </b-button-group>
                  <b-button-group class="mb-2">
                    <b-button :pressed="ilo.smallOpacitiesZones.includes('RMiddle')" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSmallOpacitiesZone('RMiddle')" :variant="ilo.smallOpacitiesZones.includes('RMiddle') ? 'primary-white' : 'outline-primary-white'" class="rounded-left">Middle</b-button>
                    <b-button :pressed="ilo.smallOpacitiesZones.includes('LMiddle')" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSmallOpacitiesZone('LMiddle')" :variant="ilo.smallOpacitiesZones.includes('LMiddle') ? 'primary-white' : 'outline-primary-white'" class="rounded-right">Middle</b-button>
                  </b-button-group>
                  <b-button-group>
                    <b-button :pressed="ilo.smallOpacitiesZones.includes('RLower')" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSmallOpacitiesZone('RLower')" :variant="ilo.smallOpacitiesZones.includes('RLower') ? 'primary-white' : 'outline-primary-white'" class="rounded-left">Lower</b-button>
                    <b-button :pressed="ilo.smallOpacitiesZones.includes('LLower')" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSmallOpacitiesZone('LLower')" :variant="ilo.smallOpacitiesZones.includes('LLower') ? 'primary-white' : 'outline-primary-white'" class="rounded-right">Lower</b-button>
                  </b-button-group>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <div class="form-group">
                <label for="ilo_technicalQuality">
                  <small class="ml-2">Shape and size: p, q, r, s, t or u</small><br />
                  <small class="ml-2">(Consult standard radiograph. Two symbols required:</small><br />
                  <small class="ml-2">mark one primary and one secondary.)</small>
                </label>
              </div>
            </b-col>
            <b-col cols="3">
              <div class="form-group float-right text-right">
                <b-button-group vertical>
                  <b-button-group class="mb-2">
                    <b-button disabled variant="outline-primary-white" class="rounded-left px-4">Primary</b-button>
                  </b-button-group>
                  <b-button-group class="mb-2">
                    <b-button :pressed="ilo.smallOpacitiesSize.primary === 'p'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacitiesSize.primary = 'p'" :variant="ilo.smallOpacitiesSize.primary === 'p' ? 'primary-white' : 'outline-primary-white'" class="rounded-left">p</b-button>
                    <b-button :pressed="ilo.smallOpacitiesSize.primary === 's'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacitiesSize.primary = 's'" :variant="ilo.smallOpacitiesSize.primary === 's' ? 'primary-white' : 'outline-primary-white'" class="rounded-right">s</b-button>
                  </b-button-group>
                  <b-button-group class="mb-2">
                    <b-button :pressed="ilo.smallOpacitiesSize.primary === 'q'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacitiesSize.primary = 'q'" :variant="ilo.smallOpacitiesSize.primary === 'q' ? 'primary-white' : 'outline-primary-white'" class="rounded-left">q</b-button>
                    <b-button :pressed="ilo.smallOpacitiesSize.primary === 't'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacitiesSize.primary = 't'" :variant="ilo.smallOpacitiesSize.primary === 't' ? 'primary-white' : 'outline-primary-white'" class="rounded-right">t</b-button>
                  </b-button-group>
                  <b-button-group>
                    <b-button :pressed="ilo.smallOpacitiesSize.primary === 'r'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacitiesSize.primary = 'r'" :variant="ilo.smallOpacitiesSize.primary === 'r' ? 'primary-white' : 'outline-primary-white'" class="rounded-left">r</b-button>
                    <b-button :pressed="ilo.smallOpacitiesSize.primary === 'u'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacitiesSize.primary = 'u'" :variant="ilo.smallOpacitiesSize.primary === 'u' ? 'primary-white' : 'outline-primary-white'" class="rounded-right">u</b-button>
                  </b-button-group>
                </b-button-group>
              </div>
            </b-col>
            <b-col cols="3">
              <div class="form-group float-right text-right">
                <b-button-group vertical>
                  <b-button-group class="mb-2">
                    <b-button disabled variant="outline-primary-white" class="rounded-left">Secondary</b-button>
                  </b-button-group>
                  <b-button-group class="mb-2">
                    <b-button :pressed="ilo.smallOpacitiesSize.secondary === 'p'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacitiesSize.secondary = 'p'" :variant="ilo.smallOpacitiesSize.secondary === 'p' ? 'primary-white' : 'outline-primary-white'" class="rounded-left">p</b-button>
                    <b-button :pressed="ilo.smallOpacitiesSize.secondary === 's'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacitiesSize.secondary = 's'" :variant="ilo.smallOpacitiesSize.secondary === 's' ? 'primary-white' : 'outline-primary-white'" class="rounded-right">s</b-button>
                  </b-button-group>
                  <b-button-group class="mb-2">
                    <b-button :pressed="ilo.smallOpacitiesSize.secondary === 'q'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacitiesSize.secondary = 'q'" :variant="ilo.smallOpacitiesSize.secondary === 'q' ? 'primary-white' : 'outline-primary-white'" class="rounded-left">q</b-button>
                    <b-button :pressed="ilo.smallOpacitiesSize.secondary === 't'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacitiesSize.secondary = 't'" :variant="ilo.smallOpacitiesSize.secondary === 't' ? 'primary-white' : 'outline-primary-white'" class="rounded-right">t</b-button>
                  </b-button-group>
                  <b-button-group>
                    <b-button :pressed="ilo.smallOpacitiesSize.secondary === 'r'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacitiesSize.secondary = 'r'" :variant="ilo.smallOpacitiesSize.secondary === 'r' ? 'primary-white' : 'outline-primary-white'" class="rounded-left">r</b-button>
                    <b-button :pressed="ilo.smallOpacitiesSize.secondary === 'u'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.smallOpacitiesSize.secondary = 'u'" :variant="ilo.smallOpacitiesSize.secondary === 'u' ? 'primary-white' : 'outline-primary-white'" class="rounded-right">u</b-button>
                  </b-button-group>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <div class="form-group">
                <label for="ilo_largeOpacities">
                  <b>Large opacities</b><br />
                  <small class="ml-2">Mark 0 for none or mark A, B, or C</small>
                </label>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="form-group float-right text-right">
                <b-button-group>
                  <b-button :pressed="ilo.largeOpacities === '0'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.largeOpacities = '0'" :variant="ilo.largeOpacities === '0' ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.largeOpacities === 'A'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.largeOpacities = 'A'" :variant="ilo.largeOpacities === 'A' ? 'primary-white' : 'outline-primary-white'">A</b-button>
                  <b-button :pressed="ilo.largeOpacities === 'B'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.largeOpacities = 'B'" :variant="ilo.largeOpacities === 'B' ? 'primary-white' : 'outline-primary-white'">B</b-button>
                  <b-button :pressed="ilo.largeOpacities === 'C'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.largeOpacities = 'C'" :variant="ilo.largeOpacities === 'C' ? 'primary-white' : 'outline-primary-white'">C</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
          </b-row>
          <hr class="border-blue" />
          <b-row>
            <b-col cols="6">
              <div class="form-group">
                <h6 class="color-white mb-3">PLEURAL ABNORMALITIES</h6>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="form-group float-right text-right">
                <b-button-group>
                  <b-button :pressed="ilo.pleuralAbnormalities === 'Yes'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralAbnormalities = 'Yes'" :variant="ilo.pleuralAbnormalities === 'Yes' ? 'primary-white' : 'outline-primary-white'">Yes</b-button>
                  <b-button class="px-3" :pressed="ilo.pleuralAbnormalities === 'No'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralAbnormalities = 'No'" :variant="ilo.pleuralAbnormalities === 'No' ? 'primary-white' : 'outline-primary-white'">No</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
          </b-row>
          <p v-if="ilo.pleuralAbnormalities === 'Yes'" class="mb-1"><b>Pleural Plaques</b></p>
          <b-row v-if="ilo.pleuralAbnormalities === 'Yes'">
            <b-col cols="2">
              <div class="form-group">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Site</small><br />
                  <small class="ml-2">(Mark appropriate</small><br />
                  <small class="ml-2">boxes)</small><br />
                </label>
              </div>
            </b-col>
            <b-col cols="2">
              <div class="form-group">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Calcification</small><br />
                  <small class="ml-2">(Mark)</small><br />
                </label>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="form-group">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Extent</small><br />
                  <small class="ml-2">(chest wall; combined for in-profile and face-on)</small><br />
                  <small class="ml-3">up to 1/4 of lateral chest wall = 1</small><br />
                  <small class="ml-3">1/4 to 1/2 of lateral chest wall = 2</small><br />
                  <small class="ml-3">> 1/2 of lateral chest wall = 3</small><br />
                </label>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="form-group">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Width</small><br />
                  <small class="ml-2">(optional; 3mm minimum width required)</small><br />
                  <small class="ml-3">3mm to 5mm = a</small><br />
                  <small class="ml-3">5mm to 10mm = b</small><br />
                  <small class="ml-3">> 10mm = c</small><br />
                </label>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="ilo.pleuralAbnormalities === 'Yes'">
            <b-col cols="2">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Chest wall in profile</small><br />
                </label>
              </div>
              <div class="form-group ml-2">
                <b-button-group>
                  <b-button :pressed="ilo.pleuralPlaques.chestWall.site.indexOf('0') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('chestWall', 'site', '0')" :variant="ilo.pleuralPlaques.chestWall.site.indexOf('0') >= 0 ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.chestWall.site.indexOf('R') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('chestWall', 'site', 'R')" :variant="ilo.pleuralPlaques.chestWall.site.indexOf('R') >= 0 ? 'primary-white' : 'outline-primary-white'">R</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.chestWall.site.indexOf('L') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('chestWall', 'site', 'L')" :variant="ilo.pleuralPlaques.chestWall.site.indexOf('L') >= 0 ? 'primary-white' : 'outline-primary-white'">L</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
            <b-col cols="2">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">&nbsp;</small><br />
                </label>
              </div>
              <div class="form-group ml-2">
                <b-button-group>
                  <b-button :pressed="ilo.pleuralPlaques.chestWall.calcification.indexOf('0') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('chestWall', 'calcification', '0')" :variant="ilo.pleuralPlaques.chestWall.calcification.indexOf('0') >= 0 ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.chestWall.calcification.indexOf('R') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('chestWall', 'calcification', 'R')" :variant="ilo.pleuralPlaques.chestWall.calcification.indexOf('R') >= 0 ? 'primary-white' : 'outline-primary-white'">R</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.chestWall.calcification.indexOf('L') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('chestWall', 'calcification', 'L')" :variant="ilo.pleuralPlaques.chestWall.calcification.indexOf('L') >= 0 ? 'primary-white' : 'outline-primary-white'">L</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">&nbsp;</small><br />
                </label>
              </div>
              <b-row>
                <b-col>
                  <div class="form-group ml-2">
                    <b-button-group>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.extent.right.enabled === '0'" :disabled="_report.info && _report.info.status === 'Completed'" @click="() => { ilo.pleuralPlaques.chestWall.extent.right.enabled = '0'; ilo.pleuralPlaques.chestWall.extent.right.size = null; }" :variant="ilo.pleuralPlaques.chestWall.extent.right.enabled === '0' ? 'primary-white' : 'outline-primary-white'">0</b-button>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.extent.right.enabled === 'R'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.extent.right.enabled = 'R'" :variant="ilo.pleuralPlaques.chestWall.extent.right.enabled === 'R' ? 'primary-white' : 'outline-primary-white'">R</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                  <div class="form-group ml-2" v-if="ilo.pleuralPlaques.chestWall.extent.right.enabled === 'R'">
                    <b-button-group>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.extent.right.size === '1'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.extent.right.size = '1'" :variant="ilo.pleuralPlaques.chestWall.extent.right.size === '1' ? 'primary-white' : 'outline-primary-white'">1</b-button>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.extent.right.size === '2'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.extent.right.size = '2'" :variant="ilo.pleuralPlaques.chestWall.extent.right.size === '2' ? 'primary-white' : 'outline-primary-white'">2</b-button>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.extent.right.size === '3'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.extent.right.size = '3'" :variant="ilo.pleuralPlaques.chestWall.extent.right.size === '3' ? 'primary-white' : 'outline-primary-white'">3</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                </b-col>
                <b-col>
                  <div class="form-group ml-2">
                    <b-button-group>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.extent.left.enabled === '0'" :disabled="_report.info && _report.info.status === 'Completed'" @click="() => { ilo.pleuralPlaques.chestWall.extent.left.enabled = '0'; ilo.pleuralPlaques.chestWall.extent.left.size = null; }" :variant="ilo.pleuralPlaques.chestWall.extent.left.enabled === '0' ? 'primary-white' : 'outline-primary-white'">0</b-button>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.extent.left.enabled === 'L'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.extent.left.enabled = 'L'" :variant="ilo.pleuralPlaques.chestWall.extent.left.enabled === 'L' ? 'primary-white' : 'outline-primary-white'">L</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                  <div class="form-group ml-2" v-if="ilo.pleuralPlaques.chestWall.extent.left.enabled === 'L'">
                    <b-button-group>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.extent.left.size === '1'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.extent.left.size = '1'" :variant="ilo.pleuralPlaques.chestWall.extent.left.size === '1' ? 'primary-white' : 'outline-primary-white'">1</b-button>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.extent.left.size === '2'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.extent.left.size = '2'" :variant="ilo.pleuralPlaques.chestWall.extent.left.size === '2' ? 'primary-white' : 'outline-primary-white'">2</b-button>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.extent.left.size === '3'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.extent.left.size = '3'" :variant="ilo.pleuralPlaques.chestWall.extent.left.size === '3' ? 'primary-white' : 'outline-primary-white'">3</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">&nbsp;</small><br />
                </label>
              </div>
              <b-row>
                <b-col>
                  <div class="form-group ml-2">
                    <b-button-group>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.width.right.enabled === 'R'" :disabled="_report.info && _report.info.status === 'Completed'" @click="() => { if (ilo.pleuralPlaques.chestWall.width.right.enabled === 'R') { ilo.pleuralPlaques.chestWall.width.right.enabled = null; ilo.pleuralPlaques.chestWall.width.right.size = null } else ilo.pleuralPlaques.chestWall.width.right.enabled = 'R' }" :variant="ilo.pleuralPlaques.chestWall.width.right.enabled === 'R' ? 'primary-white' : 'outline-primary-white'">R</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                  <div class="form-group ml-2" v-if="ilo.pleuralPlaques.chestWall.width.right.enabled === 'R'">
                    <b-button-group>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.width.right.size === 'a'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.width.right.size = 'a'" :variant="ilo.pleuralPlaques.chestWall.width.right.size === 'a' ? 'primary-white' : 'outline-primary-white'">a</b-button>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.width.right.size === 'b'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.width.right.size = 'b'" :variant="ilo.pleuralPlaques.chestWall.width.right.size === 'b' ? 'primary-white' : 'outline-primary-white'">b</b-button>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.width.right.size === 'c'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.width.right.size = 'c'" :variant="ilo.pleuralPlaques.chestWall.width.right.size === 'c' ? 'primary-white' : 'outline-primary-white'">c</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                </b-col>
                <b-col>
                  <div class="form-group ml-2">
                    <b-button-group>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.width.left.enabled === 'L'" :disabled="_report.info && _report.info.status === 'Completed'" @click="() => { if (ilo.pleuralPlaques.chestWall.width.left.enabled === 'L') { ilo.pleuralPlaques.chestWall.width.left.enabled = null; ilo.pleuralPlaques.chestWall.width.left.size = null } else ilo.pleuralPlaques.chestWall.width.left.enabled = 'L' }" :variant="ilo.pleuralPlaques.chestWall.width.left.enabled === 'L' ? 'primary-white' : 'outline-primary-white'">L</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                  <div class="form-group ml-2" v-if="ilo.pleuralPlaques.chestWall.width.left.enabled === 'L'">
                    <b-button-group>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.width.left.size === 'a'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.width.left.size = 'a'" :variant="ilo.pleuralPlaques.chestWall.width.left.size === 'a' ? 'primary-white' : 'outline-primary-white'">a</b-button>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.width.left.size === 'b'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.width.left.size = 'b'" :variant="ilo.pleuralPlaques.chestWall.width.left.size === 'b' ? 'primary-white' : 'outline-primary-white'">b</b-button>
                      <b-button :pressed="ilo.pleuralPlaques.chestWall.width.left.size === 'c'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.pleuralPlaques.chestWall.width.left.size = 'c'" :variant="ilo.pleuralPlaques.chestWall.width.left.size === 'c' ? 'primary-white' : 'outline-primary-white'">c</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="ilo.pleuralAbnormalities === 'Yes'">
            <b-col cols="2">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Face-on</small><br />
                </label>
              </div>
              <div class="form-group ml-2">
                <b-button-group>
                  <b-button :pressed="ilo.pleuralPlaques.faceOn.site.indexOf('0') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('faceOn', 'site', '0')" :variant="ilo.pleuralPlaques.faceOn.site.indexOf('0') >= 0 ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.faceOn.site.indexOf('R') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('faceOn', 'site', 'R')" :variant="ilo.pleuralPlaques.faceOn.site.indexOf('R') >= 0 ? 'primary-white' : 'outline-primary-white'">R</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.faceOn.site.indexOf('L') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('faceOn', 'site', 'L')" :variant="ilo.pleuralPlaques.faceOn.site.indexOf('L') >= 0 ? 'primary-white' : 'outline-primary-white'">L</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
            <b-col cols="2">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">&nbsp;</small><br />
                </label>
              </div>
              <div class="form-group ml-2">
                <b-button-group>
                  <b-button :pressed="ilo.pleuralPlaques.faceOn.calcification.indexOf('0') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('faceOn', 'calcification', '0')" :variant="ilo.pleuralPlaques.faceOn.calcification.indexOf('0') >= 0 ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.faceOn.calcification.indexOf('R') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('faceOn', 'calcification', 'R')" :variant="ilo.pleuralPlaques.faceOn.calcification.indexOf('R') >= 0 ? 'primary-white' : 'outline-primary-white'">R</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.faceOn.calcification.indexOf('L') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('faceOn', 'calcification', 'L')" :variant="ilo.pleuralPlaques.faceOn.calcification.indexOf('L') >= 0 ? 'primary-white' : 'outline-primary-white'">L</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4"></b-col>
          </b-row>
          <b-row v-if="ilo.pleuralAbnormalities === 'Yes'">
            <b-col cols="2">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Diaphragm</small><br />
                </label>
              </div>
              <div class="form-group ml-2">
                <b-button-group>
                  <b-button :pressed="ilo.pleuralPlaques.diaphragm.site.indexOf('0') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('diaphragm', 'site', '0')" :variant="ilo.pleuralPlaques.diaphragm.site.indexOf('0') >= 0 ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.diaphragm.site.indexOf('R') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('diaphragm', 'site', 'R')" :variant="ilo.pleuralPlaques.diaphragm.site.indexOf('R') >= 0 ? 'primary-white' : 'outline-primary-white'">R</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.diaphragm.site.indexOf('L') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('diaphragm', 'site', 'L')" :variant="ilo.pleuralPlaques.diaphragm.site.indexOf('L') >= 0 ? 'primary-white' : 'outline-primary-white'">L</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
            <b-col cols="2">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">&nbsp;</small><br />
                </label>
              </div>
              <div class="form-group ml-2">
                <b-button-group>
                  <b-button :pressed="ilo.pleuralPlaques.diaphragm.calcification.indexOf('0') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('diaphragm', 'calcification', '0')" :variant="ilo.pleuralPlaques.diaphragm.calcification.indexOf('0') >= 0 ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.diaphragm.calcification.indexOf('R') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('diaphragm', 'calcification', 'R')" :variant="ilo.pleuralPlaques.diaphragm.calcification.indexOf('R') >= 0 ? 'primary-white' : 'outline-primary-white'">R</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.diaphragm.calcification.indexOf('L') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('diaphragm', 'calcification', 'L')" :variant="ilo.pleuralPlaques.diaphragm.calcification.indexOf('L') >= 0 ? 'primary-white' : 'outline-primary-white'">L</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4"></b-col>
          </b-row>
          <b-row v-if="ilo.pleuralAbnormalities === 'Yes'">
            <b-col cols="2">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Other site(s)</small><br />
                </label>
              </div>
              <div class="form-group ml-2">
                <b-button-group>
                  <b-button :pressed="ilo.pleuralPlaques.otherSites.site.indexOf('0') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('otherSites', 'site', '0')" :variant="ilo.pleuralPlaques.otherSites.site.indexOf('0') >= 0 ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.otherSites.site.indexOf('R') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('otherSites', 'site', 'R')" :variant="ilo.pleuralPlaques.otherSites.site.indexOf('R') >= 0 ? 'primary-white' : 'outline-primary-white'">R</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.otherSites.site.indexOf('L') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('otherSites', 'site', 'L')" :variant="ilo.pleuralPlaques.otherSites.site.indexOf('L') >= 0 ? 'primary-white' : 'outline-primary-white'">L</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
            <b-col cols="2">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">&nbsp;</small><br />
                </label>
              </div>
              <div class="form-group ml-2">
                <b-button-group>
                  <b-button :pressed="ilo.pleuralPlaques.otherSites.calcification.indexOf('0') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('otherSites', 'calcification', '0')" :variant="ilo.pleuralPlaques.otherSites.calcification.indexOf('0') >= 0 ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.otherSites.calcification.indexOf('R') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('otherSites', 'calcification', 'R')" :variant="ilo.pleuralPlaques.otherSites.calcification.indexOf('R') >= 0 ? 'primary-white' : 'outline-primary-white'">R</b-button>
                  <b-button :pressed="ilo.pleuralPlaques.otherSites.calcification.indexOf('L') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleSite('otherSites', 'calcification', 'L')" :variant="ilo.pleuralPlaques.otherSites.calcification.indexOf('L') >= 0 ? 'primary-white' : 'outline-primary-white'">L</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4"></b-col>
          </b-row>
          <p v-if="ilo.pleuralAbnormalities === 'Yes'" class="mb-1"><b>Costophrenic Angle Obliteration</b></p>
          <b-row v-if="ilo.pleuralAbnormalities === 'Yes'">
            <b-col cols="2">
              <div class="form-group ml-2">
                <b-button-group>
                  <b-button :pressed="ilo.costophrenic.indexOf('0') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleCostophrenic('0')" :variant="ilo.costophrenic.indexOf('0') >= 0 ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.costophrenic.indexOf('R') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleCostophrenic('R')" :variant="ilo.costophrenic.indexOf('R') >= 0 ? 'primary-white' : 'outline-primary-white'">R</b-button>
                  <b-button :pressed="ilo.costophrenic.indexOf('L') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleCostophrenic('L')" :variant="ilo.costophrenic.indexOf('L') >= 0 ? 'primary-white' : 'outline-primary-white'">L</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
            <b-col cols="2"></b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4"></b-col>
          </b-row>
          <p v-if="ilo.pleuralAbnormalities === 'Yes'" class="mb-1"><b>Diffuse Pleural Thickening</b></p>
          <b-row v-if="ilo.pleuralAbnormalities === 'Yes'">
            <b-col cols="2">
              <div class="form-group">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Site</small><br />
                  <small class="ml-2">(Mark appropriate</small><br />
                  <small class="ml-2">boxes)</small><br />
                </label>
              </div>
            </b-col>
            <b-col cols="2">
              <div class="form-group">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Calcification</small><br />
                  <small class="ml-2">(Mark)</small><br />
                </label>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="form-group">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Extent</small><br />
                  <small class="ml-2">(chest wall; combined for in-profile and face-on)</small><br />
                  <small class="ml-3">up to 1/4 of lateral chest wall = 1</small><br />
                  <small class="ml-3">1/4 to 1/2 of lateral chest wall = 2</small><br />
                  <small class="ml-3">> 1/2 of lateral chest wall = 3</small><br />
                </label>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="form-group">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Width</small><br />
                  <small class="ml-2">(optional; 3mm minimum width required)</small><br />
                  <small class="ml-3">3mm to 5mm = a</small><br />
                  <small class="ml-3">5mm to 10mm = b</small><br />
                  <small class="ml-3">> 10mm = c</small><br />
                </label>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="ilo.pleuralAbnormalities === 'Yes'">
            <b-col cols="2">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Chest wall in profile</small><br />
                </label>
              </div>
              <div class="form-group ml-2">
                <b-button-group>
                  <b-button :pressed="ilo.diffusePleural.chestWall.site.indexOf('0') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleDifffuseSite('chestWall', 'site', '0')" :variant="ilo.diffusePleural.chestWall.site.indexOf('0') >= 0 ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.diffusePleural.chestWall.site.indexOf('R') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleDifffuseSite('chestWall', 'site', 'R')" :variant="ilo.diffusePleural.chestWall.site.indexOf('R') >= 0 ? 'primary-white' : 'outline-primary-white'">R</b-button>
                  <b-button :pressed="ilo.diffusePleural.chestWall.site.indexOf('L') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleDifffuseSite('chestWall', 'site', 'L')" :variant="ilo.diffusePleural.chestWall.site.indexOf('L') >= 0 ? 'primary-white' : 'outline-primary-white'">L</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
            <b-col cols="2">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">&nbsp;</small><br />
                </label>
              </div>
              <div class="form-group ml-2">
                <b-button-group>
                  <b-button :pressed="ilo.diffusePleural.chestWall.calcification.indexOf('0') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleDifffuseSite('chestWall', 'calcification', '0')" :variant="ilo.diffusePleural.chestWall.calcification.indexOf('0') >= 0 ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.diffusePleural.chestWall.calcification.indexOf('R') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleDifffuseSite('chestWall', 'calcification', 'R')" :variant="ilo.diffusePleural.chestWall.calcification.indexOf('R') >= 0 ? 'primary-white' : 'outline-primary-white'">R</b-button>
                  <b-button :pressed="ilo.diffusePleural.chestWall.calcification.indexOf('L') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleDifffuseSite('chestWall', 'calcification', 'L')" :variant="ilo.diffusePleural.chestWall.calcification.indexOf('L') >= 0 ? 'primary-white' : 'outline-primary-white'">L</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">&nbsp;</small><br />
                </label>
              </div>
              <b-row>
                <b-col>
                  <div class="form-group ml-2">
                    <b-button-group>
                      <b-button :pressed="ilo.diffusePleural.chestWall.extent.right.enabled === '0'" :disabled="_report.info && _report.info.status === 'Completed'" @click="() => { ilo.diffusePleural.chestWall.extent.right.enabled = '0'; ilo.diffusePleural.chestWall.extent.right.size = null; }" :variant="ilo.diffusePleural.chestWall.extent.right.enabled === '0' ? 'primary-white' : 'outline-primary-white'">0</b-button>
                      <b-button :pressed="ilo.diffusePleural.chestWall.extent.right.enabled === 'R'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.extent.right.enabled = 'R'" :variant="ilo.diffusePleural.chestWall.extent.right.enabled === 'R' ? 'primary-white' : 'outline-primary-white'">R</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                  <div class="form-group ml-2" v-if="ilo.diffusePleural.chestWall.extent.right.enabled === 'R'">
                    <b-button-group>
                      <b-button :pressed="ilo.diffusePleural.chestWall.extent.right.size === '1'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.extent.right.size = '1'" :variant="ilo.diffusePleural.chestWall.extent.right.size === '1' ? 'primary-white' : 'outline-primary-white'">1</b-button>
                      <b-button :pressed="ilo.diffusePleural.chestWall.extent.right.size === '2'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.extent.right.size = '2'" :variant="ilo.diffusePleural.chestWall.extent.right.size === '2' ? 'primary-white' : 'outline-primary-white'">2</b-button>
                      <b-button :pressed="ilo.diffusePleural.chestWall.extent.right.size === '3'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.extent.right.size = '3'" :variant="ilo.diffusePleural.chestWall.extent.right.size === '3' ? 'primary-white' : 'outline-primary-white'">3</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                </b-col>
                <b-col>
                  <div class="form-group ml-2">
                    <b-button-group>
                      <b-button :pressed="ilo.diffusePleural.chestWall.extent.left.enabled === '0'" :disabled="_report.info && _report.info.status === 'Completed'" @click="() => { ilo.diffusePleural.chestWall.extent.left.enabled = '0'; ilo.diffusePleural.chestWall.extent.left.size = null; }" :variant="ilo.diffusePleural.chestWall.extent.left.enabled === '0' ? 'primary-white' : 'outline-primary-white'">0</b-button>
                      <b-button :pressed="ilo.diffusePleural.chestWall.extent.left.enabled === 'L'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.extent.left.enabled = 'L'" :variant="ilo.diffusePleural.chestWall.extent.left.enabled === 'L' ? 'primary-white' : 'outline-primary-white'">L</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                  <div class="form-group ml-2" v-if="ilo.diffusePleural.chestWall.extent.left.enabled === 'L'">
                    <b-button-group>
                      <b-button :pressed="ilo.diffusePleural.chestWall.extent.left.size === '1'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.extent.left.size = '1'" :variant="ilo.diffusePleural.chestWall.extent.left.size === '1' ? 'primary-white' : 'outline-primary-white'">1</b-button>
                      <b-button :pressed="ilo.diffusePleural.chestWall.extent.left.size === '2'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.extent.left.size = '2'" :variant="ilo.diffusePleural.chestWall.extent.left.size === '2' ? 'primary-white' : 'outline-primary-white'">2</b-button>
                      <b-button :pressed="ilo.diffusePleural.chestWall.extent.left.size === '3'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.extent.left.size = '3'" :variant="ilo.diffusePleural.chestWall.extent.left.size === '3' ? 'primary-white' : 'outline-primary-white'">3</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="4">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">&nbsp;</small><br />
                </label>
              </div>
              <b-row>
                <b-col>
                  <div class="form-group ml-2">
                    <b-button-group>
                      <b-button :pressed="ilo.diffusePleural.chestWall.width.right.enabled === 'R'" :disabled="_report.info && _report.info.status === 'Completed'" @click="() => { if (ilo.diffusePleural.chestWall.width.right.enabled === 'R') { ilo.diffusePleural.chestWall.width.right.enabled = null; ilo.diffusePleural.chestWall.width.right.size = null } else ilo.diffusePleural.chestWall.width.right.enabled = 'R' }" :variant="ilo.diffusePleural.chestWall.width.right.enabled === 'R' ? 'primary-white' : 'outline-primary-white'">R</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                  <div class="form-group ml-2" v-if="ilo.diffusePleural.chestWall.width.right.enabled === 'R'">
                    <b-button-group>
                      <b-button :pressed="ilo.diffusePleural.chestWall.width.right.size === 'a'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.width.right.size = 'a'" :variant="ilo.diffusePleural.chestWall.width.right.size === 'a' ? 'primary-white' : 'outline-primary-white'">a</b-button>
                      <b-button :pressed="ilo.diffusePleural.chestWall.width.right.size === 'b'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.width.right.size = 'b'" :variant="ilo.diffusePleural.chestWall.width.right.size === 'b' ? 'primary-white' : 'outline-primary-white'">b</b-button>
                      <b-button :pressed="ilo.diffusePleural.chestWall.width.right.size === 'c'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.width.right.size = 'c'" :variant="ilo.diffusePleural.chestWall.width.right.size === 'c' ? 'primary-white' : 'outline-primary-white'">c</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                </b-col>
                <b-col>
                  <div class="form-group ml-2">
                    <b-button-group>
                      <b-button :pressed="ilo.diffusePleural.chestWall.width.left.enabled === 'L'" :disabled="_report.info && _report.info.status === 'Completed'" @click="() => { if (ilo.diffusePleural.chestWall.width.left.enabled === 'L') { ilo.diffusePleural.chestWall.width.left.enabled = null; ilo.diffusePleural.chestWall.width.left.size = null } else ilo.diffusePleural.chestWall.width.left.enabled = 'L' }" :variant="ilo.diffusePleural.chestWall.width.left.enabled === 'L' ? 'primary-white' : 'outline-primary-white'">L</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                  <div class="form-group ml-2" v-if="ilo.diffusePleural.chestWall.width.left.enabled === 'L'">
                    <b-button-group>
                      <b-button :pressed="ilo.diffusePleural.chestWall.width.left.size === 'a'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.width.left.size = 'a'" :variant="ilo.diffusePleural.chestWall.width.left.size === 'a' ? 'primary-white' : 'outline-primary-white'">a</b-button>
                      <b-button :pressed="ilo.diffusePleural.chestWall.width.left.size === 'b'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.width.left.size = 'b'" :variant="ilo.diffusePleural.chestWall.width.left.size === 'b' ? 'primary-white' : 'outline-primary-white'">b</b-button>
                      <b-button :pressed="ilo.diffusePleural.chestWall.width.left.size === 'c'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.diffusePleural.chestWall.width.left.size = 'c'" :variant="ilo.diffusePleural.chestWall.width.left.size === 'c' ? 'primary-white' : 'outline-primary-white'">c</b-button>
                    </b-button-group>
                    <div class="help-block with-errors"></div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="ilo.pleuralAbnormalities === 'Yes'">
            <b-col cols="2">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">Face-on</small><br />
                </label>
              </div>
              <div class="form-group ml-2">
                <b-button-group>
                  <b-button :pressed="ilo.diffusePleural.faceOn.site.indexOf('0') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleDifffuseSite('faceOn', 'site', '0')" :variant="ilo.diffusePleural.faceOn.site.indexOf('0') >= 0 ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.diffusePleural.faceOn.site.indexOf('R') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleDifffuseSite('faceOn', 'site', 'R')" :variant="ilo.diffusePleural.faceOn.site.indexOf('R') >= 0 ? 'primary-white' : 'outline-primary-white'">R</b-button>
                  <b-button :pressed="ilo.diffusePleural.faceOn.site.indexOf('L') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleDifffuseSite('faceOn', 'site', 'L')" :variant="ilo.diffusePleural.faceOn.site.indexOf('L') >= 0 ? 'primary-white' : 'outline-primary-white'">L</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
            <b-col cols="2">
              <div class="form-group mb-0">
                <label for="ilo_pleuralAbnormalities">
                  <small class="ml-2">&nbsp;</small><br />
                </label>
              </div>
              <div class="form-group ml-2">
                <b-button-group>
                  <b-button :pressed="ilo.diffusePleural.faceOn.calcification.indexOf('0') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleDifffuseSite('faceOn', 'calcification', '0')" :variant="ilo.diffusePleural.faceOn.calcification.indexOf('0') >= 0 ? 'primary-white' : 'outline-primary-white'">0</b-button>
                  <b-button :pressed="ilo.diffusePleural.faceOn.calcification.indexOf('R') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleDifffuseSite('faceOn', 'calcification', 'R')" :variant="ilo.diffusePleural.faceOn.calcification.indexOf('R') >= 0 ? 'primary-white' : 'outline-primary-white'">R</b-button>
                  <b-button :pressed="ilo.diffusePleural.faceOn.calcification.indexOf('L') >= 0" :disabled="_report.info && _report.info.status === 'Completed'" @click="toggleDifffuseSite('faceOn', 'calcification', 'L')" :variant="ilo.diffusePleural.faceOn.calcification.indexOf('L') >= 0 ? 'primary-white' : 'outline-primary-white'">L</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4"></b-col>
          </b-row>
          <hr class="border-blue" />
          <b-row>
            <b-col cols="6">
              <div class="form-group">
                <h6 class="color-white mb-2">* SYMBOLS</h6>
                <small class="ml-2">Circle as appropriate; if <b>od</b> circled,</small><br />
                <small class="ml-2"><b>Comment</b> must be made below.)</small>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="form-group float-right text-right">
                <b-button-group>
                  <b-button :pressed="ilo.symbols === 'Yes'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.symbols = 'Yes'" :variant="ilo.symbols === 'Yes' ? 'primary-white' : 'outline-primary-white'">Yes</b-button>
                  <b-button class="px-3" :pressed="ilo.symbols === 'No'" :disabled="_report.info && _report.info.status === 'Completed'" @click="ilo.symbols = 'No'" :variant="ilo.symbols === 'No' ? 'primary-white' : 'outline-primary-white'">No</b-button>
                </b-button-group>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="ilo.symbols === 'Yes'">
            <b-col cols="12">
              <div class="form-group">
                <b-button-group vertical>
                  <b-button-group>
                    <b-button v-for="symbol in ['aa', 'at', 'ax', 'bu', 'ca', 'cg', 'cn', 'co', 'cp', 'cv', 'di', 'ef', 'em', 'es']"
                              :key="symbol"
                              @click="toggleSymbol(symbol)"
                              :pressed="ilo.symbolValues.includes(symbol)"
                              :variant="ilo.symbolValues.includes(symbol) ? 'outline-primary-white' : 'symbol-button'"
                              class="symbol-button">
                      {{ symbol }}
                    </b-button>
                  </b-button-group>
                  <b-button-group>
                    <b-button v-for="symbol in ['fr', 'hi', 'ho', 'id', 'ih', 'kl', 'me', 'pa', 'pb', 'pi', 'px', 'ra', 'rp', 'tb', 'od']"
                              :key="symbol"
                              @click="toggleSymbol(symbol)"
                              :pressed="ilo.symbolValues.includes(symbol)"
                              :variant="ilo.symbolValues.includes(symbol) ? 'outline-primary-white' : 'symbol-button'"
                              class="symbol-button">
                      {{ symbol }}
                    </b-button>
                  </b-button-group>
                </b-button-group>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="ilo.symbolValues.includes('od')">
            <b-col cols="12">
              <div class="form-group">
                <label for="ilo_symbols">Comment</label>
                <b-form-textarea id="ilo_symbols" v-model="ilo.comment" name="comment"></b-form-textarea>
                <div class="help-block with-errors"></div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <hr class="border-blue" />
      <b-row>
        <b-col md="6" offset-md="6">
          <input :disabled="newPathology.active" type="button" @click.prevent="saveIloReport()" class="btn btn-primary btn-send float-right btn-sm rounded-pill px-3 mr-2" value="Save Report">
          <input type="button" @click.prevent="toggle('ilo')" class="btn btn-outline-primary btn-send float-right btn-sm rounded-pill px-3 mr-2" value="Close">
          <input type="button" @click.prevent="downloadIloPdf()" class="btn btn-outline-primary btn-send float-right btn-sm rounded-pill px-3 mr-2" value="Download PDF">
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<style scoped>
.slidein {
  width: 50rem;
  min-height: 10rem;
  position: fixed;
  z-index: 100;
  top: 0;
  right: -100%;
  box-shadow: 1px 1px 10px rgba(0,0,0,.5);
  transition: all .5s ease-in-out;
}
.slidein-ilo {
  width: 65rem;
  height: calc(100vh - 1rem);
}
.open {
  right: 0;
}
</style>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

import { staffReportService, staffSubmissionService } from '@/services'

import { mapActions, mapState } from 'vuex'
import { apiRoute } from './../../../helpers'

import BoundedImage from './BoundedImage'
import BoundedBox from './BoundedBox'
import BoundedBoxCtr from './BoundedBoxCTR'
// import Lightbox from 'vue-lightbox'

import moment from 'moment'

export default {
  components: {
    BoundedImage,
    BoundedBox,
    BoundedBoxCtr, /*,
    Lightbox */
    VueCropper
  },
  data () {
    return {
      open: {
        feedback: false,
        image: false,
        patient: false,
        previous: true,
        ilo: false
      },
      selectedHeatmap: 'original',
      key: 1,
      brightness: 100,
      contrast: 100,
      saturate: 100,
      sepia: 0,
      invert: 0,
      hue: 0,
      shownList: {},
      concordanceList: {},
      other_tags: [],
      other_coords: [],
      diagnosisAgree: true,
      otherDiagnoses: [],
      updated: 0,
      labellerKey: 0,
      colours: ['#C77D3D', '#A4414A', '#7D1E5F', '#5C0F6B', '#3C0A6F', '#1E0A6E', '#0B0C63', '#0A1052', '#0C1441', '#13192F', '#1D1E1E', '#2A2213', '#3A2709', '#4B2C00', '#5D3100', '#6F3600', '#813B00', '#933F00', '#A54200', '#B74400'],
      oldcolours: ['#5E50F9', '#6610f2', '#6a008a', '#E91E63', '#f96868', '#f2a654', '#f6e84e', '#46c35f', '#58d8a3', '#57c7d4', '#4d83ff', '#71c016', '#7859df', '#ffc100', '#ff4747'],
      dimensions: {
        w: 0,
        h: 0,
        ratio: 0.00
      },
      loaded: false,
      report: {
        status: 'In Progress',
        baseline: 'No',
        resultType: null,
        technical: [
          { view: 'AP view', quality: 'Acceptable', reason: null }
        ],
        reporter: {
          investigatorCode: null,
          imageRecorded: 'Yes',
          consistentWithTB: 'No'
        },
        boneAndSoftTissue: null,
        conclusion: null,
        findings: {
          lines: null,
          lungs: null,
          pleura: null,
          heart: null,
          bones: null
        },
        parenchyma: [],
        other: null,
        other_name: null
      },
      updatedPathology: 1,
      otherSubmissions: [],
      updatedOthers: 1,
      activeImage: 'current',
      newPathology: {
        active: false,
        index: null,
        name: null,
        other: null,
        coords: {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        }
      },
      labeller: {
        width: 0,
        height: 0
      },
      auditChanged: false,
      ilo: {
        readerCode: null,
        radiographIdentifier: null,
        dateOfReading: null,
        dateOfRadiograph: null,
        technicalQuality: null,
        comment: null,
        smallOpacities: null,
        smallOpacitiesZones: [],
        smallOpacitiesSize: {
          primary: null,
          secondary: null
        },
        largeOpacities: null,
        pleuralAbnormalities: null,
        pleuralPlaques: {
          chestWall: {
            site: [],
            calcification: [],
            extent: {
              right: {
                enabled: null,
                size: null
              },
              left: {
                enabled: null,
                size: null
              }
            },
            width: {
              right: {
                enabled: null,
                size: null
              },
              left: {
                enabled: null,
                size: null
              }
            }
          },
          faceOn: {
            site: [],
            calcification: []
          },
          diaphragm: {
            site: [],
            calcification: []
          },
          otherSites: {
            site: [],
            calcification: []
          }
        },
        costophrenic: [],
        diffusePleural: {
          chestWall: {
            site: [],
            calcification: [],
            extent: {
              right: {
                enabled: null,
                size: null
              },
              left: {
                enabled: null,
                size: null
              }
            },
            width: {
              right: {
                enabled: null,
                size: null
              },
              left: {
                enabled: null,
                size: null
              }
            }
          },
          faceOn: {
            site: [],
            calcification: []
          }
        },
        symbols: null,
        symbolValues: [],
        symbolsComment: null
      }
    }
  },
  props: {
    current: {
      type: Object,
      default: null
    },
    model: String
  },
  mounted () {
    if (this.filteredList && this.filteredList.length > 0) {
      this.filteredList.forEach(_pathology => {
        this.shownList[_pathology._id] = true
        this.concordanceList[_pathology._id] = true
      })
    }

    this.shownList.ctr = true
    this.concordanceList.other = false

    if (this.current.submission && this.current.submission.attachments && this.current.submission.attachments.length > 0) {
      this.getIloReport()
      const attachment = this.current.submission.attachments[0]
      if (attachment.feedback) {
        this.other_tags = attachment.feedback.other || []
        if (this.other_tags.length > 0) {
          this.$set(this.concordanceList, 'other', true)
        }

        if (attachment.feedback.diagnosis.indexOf(this.current.analysis.conclusion) >= 0) {
          this.diagnosisAgree = true
        } else {
          this.diagnosisAgree = false
        }

        if (attachment.feedback && attachment.feedback.labels && attachment.feedback.labels.length > 0) {
          const label = attachment.feedback.labels[0]
          if (label.pathologies && label.pathologies.length > 0) {
            label.pathologies.forEach(_pathology => {
              this.$set(this.concordanceList, _pathology.pathology_id, _pathology.concordance)
              this.$set(this.shownList, _pathology.pathology_id, _pathology.concordance)
            })
          }
        }
      }
    }

    this.report.reporter.investigatorCode = this.auth && this.auth.user ? this.auth.user.name : null

    if (this.filteredReportList && this.filteredReportList.length > 0 && !this.current.report) {
      this.filteredReportList.forEach(_pathology => {
        _pathology.rt = _pathology.rt ? _pathology.rt : (_pathology.ruz || _pathology.rmz || _pathology.rlz)
        _pathology.lt = _pathology.lt ? _pathology.lt : (_pathology.luz || _pathology.lmz || _pathology.llz)

        this.report.parenchyma.push(_pathology)
      })

      this.report.parenchyma.sort((a, b) => { return a.name > b.name ? 1 : -1 })
      this.report.resultType = 'Abnormal'
    } else {
      this.report.resultType = 'Normal'
    }

    this.getPatientSubmissions()
    this.getReport(this.submission._id)

    setTimeout(() => {
      console.log('Here')
      this.getLabellerDimensions()
      this.$refs.cropper.clear()
      this.$refs.cropper.reset()
      this.$refs.cropper.enable()
      this.$refs.cropper.initCrop()
      this.$refs.cropper.replace(this.selectedImage)
      this.$refs.cropper.cropper.disabled = true
      this.$refs.cropper.disable()
    }, 1000)
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      auth: state => state.auth,
      options: state => state.pathologies.options,
      _report: state => state.staffReport
    }),
    availableOptions () {
      return this.options.filter(opt => this.other_tags.indexOf(opt) === -1)
    },
    selectedImage () {
      if (this.activeImage === 'current') {
        return this.originalImage(this.current.submission)
      } else {
        const _split = this.activeImage.split('|')
        if (_split && _split.length === 2) {
          const _submission = this.filteredOthersList.find(_record => _record.submission._id === _split[0])
          if (_submission) {
            if (_split[1] === 'original') {
              return this.apiRoute(_submission.submission.attachments[0].path) + _submission.submission.attachments[0].path
            } else if (_split[1] === 'bounded') {
              return this.apiRoute(_submission.submission.attachments[0].response.bounding.path) + _submission.submission.attachments[0].response.bounding.path
            } else {
              return null
            }
          } else {
            return null
          }
        } else {
          return null
        }
      }
    },
    cardioList () {
      if (this.current.submission && this.current.submission.attachments && this.current.submission.attachments.length > 0) {
        const attachment = this.current.submission.attachments[0]
        if (attachment.response && attachment.response.labels && attachment.response.labels.length > 0) {
          const label = attachment.response.labels[0]
          if (label.pathologies && label.pathologies.length > 0) {
            const _list = []
            let _index = 0
            label.pathologies.filter(_pathology => _pathology.predicted_diagnosis.toLowerCase() === 'thoracic' || _pathology.predicted_diagnosis.toLowerCase() === 'cardio').sort((a, b) => a.predicted_diagnosis < b.predicted_diagnosis).forEach(_pathology => {
              _list.push({
                _id: _pathology._id,
                bounding_box: _pathology.bounding_box,
                predicted_diagnosis: _pathology.predicted_diagnosis,
                confidence: _pathology.confidence,
                colour: this.colours[this.filteredList.length + _index++]
              })
            })
            return _list
          } else {
            return []
          }
        } else {
          return []
        }
      } else {
        return []
      }
    },
    filteredList () {
      const _list = []
      let _index = 0

      if (this.current.submission && this.current.submission.attachments && this.current.submission.attachments.length > 0) {
        const attachment = this.current.submission.attachments[0]
        if (attachment.response && attachment.response.labels && attachment.response.labels.length > 0) {
          const label = attachment.response.labels[0]
          if (label.pathologies && label.pathologies.length > 0) {
            label.pathologies.filter(_pathology => _pathology.predicted_diagnosis.toLowerCase() !== 'thoracic' && _pathology.predicted_diagnosis.toLowerCase() !== 'cardio').forEach(_pathology => {
              _list.push({
                _id: _pathology._id,
                bounding_box: _pathology.bounding_box,
                predicted_diagnosis: _pathology.predicted_diagnosis,
                confidence: _pathology.confidence,
                colour: this.colours[_index++]
              })
            })
          }
        }
      }

      if (this.other_coords && this.other_coords.length > 0) {
        this.other_coords.forEach(_pathology => {
          _list.push({
            _id: _pathology._id,
            bounding_box: _pathology.coords,
            predicted_diagnosis: _pathology.name === 'Other' ? 'Other: ' + _pathology.other : _pathology.name,
            confidence: null,
            colour: this.colours[_index++]
          })
        })
      }

      return _list
    },
    filteredShownList () {
      console.log(this.updated)
      if (this.filteredList.length > 0) {
        const _list = this.filteredList
        const _shown = []
        _list.forEach(_pathology => {
          if (this.isShown(_pathology._id) === true) {
            _shown.push({
              _id: _pathology._id,
              bounding_box: _pathology.bounding_box,
              predicted_diagnosis: _pathology.predicted_diagnosis,
              confidence: _pathology.confidence,
              colour: _pathology.colour
            })
          }
        })
        return _shown
      } else {
        return []
      }
    },
    filteredReportList () {
      if (this.current.analysis.pathologies && this.current.analysis.pathologies.length > 0) {
        const _list = this.current.analysis.pathologies.filter(_pathology => _pathology.name.toLowerCase() !== 'thoracic' && _pathology.name.toLowerCase() !== 'cardio')
        return _list
      } else {
        return []
      }
    },
    filteredOptions () {
      return this.options.filter(_option => {
        return !this.filteredReportList.find(_pathology => _pathology.name.toLowerCase() === _option.replace(/[^a-zA-Z]/g, '').toLowerCase())
      })
    },
    filteredOthersList () {
      if (this.otherSubmissions && this.otherSubmissions.length > 0) {
        return this.otherSubmissions.filter(_submission => _submission.submission._id !== this.submission._id)
      } else {
        return []
      }
    },
    sortedParenchema () {
      return this._.orderBy(this.report.parenchyma, [(o) => { return o.name.replace(/[^a-zA-Z]/g, '').toLowerCase() }])
    },
    submission () {
      return this.current.submission
    },
    reportLoadState () {
      return this._report.status.loaded
    },
    reportSaveState () {
      return this._report.status.saved
    },
    labellerStyle () {
      if (!this.dimensions.w) {
        return {}
      } else {
        return {
          width: this.dimensions.w.toString() + 'px',
          height: this.dimensions.h.toString() + 'px'
        }
      }
    },
    canvasLeft () {
      if (this.dimensions.w && this.labeller.width) {
        const _left = parseInt((this.labeller.width - this.dimensions.w) / 2)
        return (_left <= 0 ? 0 : _left - 5)
      } else {
        return 10
      }
    },
    iloLoading () {
      return this._report.ilo.reportLoading
    }
  },
  methods: {
    ...mapActions('staffReport', {
      getReport: 'get',
      saveReport: 'save',
      saveIlo: 'saveIlo',
      getIlo: 'getIlo'
    }),
    ...mapActions('staffSubmission', {
      saveFeedback: 'feedback',
      updateLabels: 'update'
    }),
    saveIloReport () {
      const payload = this.ilo
      payload.submissionId = this.current.submission._id
      this.saveIlo(payload)
    },
    getIloReport () {
      const payload = this.current.submission._id
      this.getIlo(payload)
    },
    getLabellerDimensions: function () {
      this.labeller.width = this.$refs.labeller.clientWidth
      this.labeller.height = this.$refs.labeller.clientHeight
    },
    cropImage: function (event) {
      const data = this.$refs.cropper.getData(true)
      this.newPathology.coords = {
        x: data.x,
        y: data.y,
        w: data.width,
        h: data.height
      }
    },
    toggleEdit: function (index) {
      this.filteredList.forEach(pathology => {
        if (index === pathology._id) {
          this.newPathology = {
            active: true,
            index: index,
            name: pathology.predicted_diagnosis,
            coords: pathology.bounding_box
          }

          this.$refs.cropper.enable()
          this.$refs.cropper.initCrop()
          this.$refs.cropper.setCropBoxData({
            height: pathology.bounding_box.h * this.dimensions.ratio,
            width: pathology.bounding_box.w * this.dimensions.ratio,
            left: pathology.bounding_box.x * this.dimensions.ratio,
            top: pathology.bounding_box.y * this.dimensions.ratio
          })
        }
      })
    },
    cancelEdit: function () {
      this.newPathology.active = false
      this.newPathology.index = null
      this.newPathology.name = null
      this.newPathology.other = null
      this.newPathology.coords = {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      }
      this.$refs.cropper.clear()
      this.$refs.cropper.reset()
      this.$refs.cropper.disable()
    },
    deleteOther: function (_id) {
      for (let i = this.other_coords.length - 1; i >= 0; i--) {
        if (this.other_coords[i]._id === _id) {
          this.other_coords.splice(i, 1)

          delete this.concordanceList[_id]
          delete this.shownList[_id]

          this.updated++
        }
      }
    },
    saveEdit: function () {
      if (this.newPathology.active) {
        const imageData = this.$refs.cropper.cropper.imageData
        this.dimensions = {
          w: parseInt(imageData.width),
          h: parseInt(imageData.height),
          ratio: (imageData.width / imageData.naturalWidth + imageData.height / imageData.naturalHeight) / 2
        }
        const data = this.$refs.cropper.getData(true)
        this.newPathology.coords = {
          x: data.x,
          y: data.y,
          w: data.width,
          h: data.height
        }
        if (this.newPathology.index != null) {
          for (let i = 0; i < this.filteredList.length; i++) {
            if (this.newPathology.index === this.filteredList[i]._id) {
              if (Math.round(this.filteredList[i].bounding_box.h) !== this.newPathology.coords.h) {
                this.auditChanged = true
              } else if (Math.round(this.filteredList[i].bounding_box.w) !== this.newPathology.coords.w) {
                this.auditChanged = true
              } else if (Math.round(this.filteredList[i].bounding_box.x) !== this.newPathology.coords.x) {
                this.auditChanged = true
              } else if (Math.round(this.filteredList[i].bounding_box.y) !== this.newPathology.coords.y) {
                this.auditChanged = true
              }

              this.$set(this.filteredList[i], 'bounding_box', this.newPathology.coords)
            }
          }
        } else {
          let _id = 1
          if (this.other_coords.length > 0) {
            const _max = this.other_coords.reduce((prev, current) => (parseInt(prev._id.replace('new_', '')) > parseInt(current._id.replace('new_', ''))) ? prev : current)
            _id = parseInt(_max._id.replace('new_', '')) + 1
          }

          const _record = {
            _id: 'new_' + _id.toString(),
            name: this.newPathology.name,
            other: this.newPathology.other,
            coords: this.newPathology.coords
          }

          this.other_coords.push(_record)

          this.concordanceList[_record._id] = true
          this.shownList[_record._id] = true
          this.auditChanged = true
        }
        this.newPathology.active = false
        this.newPathology.index = null
        this.newPathology.name = null
        this.newPathology.other = null
        this.newPathology.coords = {
          x: 0,
          y: 0,
          w: 0,
          h: 0
        }
        this.$refs.cropper.clear()
        this.$refs.cropper.reset()
        this.$refs.cropper.disable()
        this.updated++
      } else {
        this.newPathology.active = true
        this.$refs.cropper.enable()
        this.$refs.cropper.initCrop()
      }
    },
    onReady: function (evt) {
      const imageData = this.$refs.cropper.getImageData()
      this.dimensions = {
        w: parseInt(imageData.width),
        h: parseInt(imageData.height),
        ratio: (imageData.width / imageData.naturalWidth + imageData.height / imageData.naturalHeight) / 2
      }
      this.loaded = true
      this.updated += 1
    },
    apiRoute: function (path) {
      if (path.indexOf('http') >= 0) {
        return ''
      } else {
        return apiRoute()
      }
    },
    addPathology: function () {
      const _name = this.report.other === 'Other' ? this.report.other_name : this.report.other
      if (this.report.other) {
        this.report.parenchyma.push({
          name: _name,
          ruz: false,
          rmz: false,
          rlz: false,
          rt: false,
          luz: false,
          lmz: false,
          llz: false,
          lt: false
        })
      }

      this.report.other = null
      this.report.other_name = null
      this.updatedPathology++
    },
    addPathologyBox: function () {
      if (!this.newPathology.active) {
        this.newPathology.active = true
        this.$refs.cropper.enable()
        this.$refs.cropper.initCrop()
      } else {
        this.saveEdit()
      }
    },
    toggleReportPathology: function (pathology, zone) {
      const _pathology = this.report.parenchyma.find(_pathology => _pathology.name === pathology)
      console.log(pathology, zone, _pathology)
      if (_pathology) {
        _pathology[zone] = !_pathology[zone]
        _pathology.rt = _pathology.ruz || _pathology.rmz || _pathology.rlz
        _pathology.lt = _pathology.luz || _pathology.lmz || _pathology.llz

        const _feedback = this.filteredList.filter(__pathology => __pathology.predicted_diagnosis === pathology)
        _feedback.forEach(__pathology => {
          if (__pathology.predicted_diagnosis === pathology) {
            if (!_pathology.rt && !_pathology.lt) {
              if (this.concordanceList[__pathology._id]) {
                this.toggleConcordance(__pathology._id)
              }
            } else {
              if (!this.concordanceList[__pathology._id]) {
                this.toggleConcordance(__pathology._id)
              }
            }
          }
        })

        if (_feedback.length === 0) {
          const _index = this.other_tags.indexOf(pathology)
          if (!_pathology.rt && !_pathology.lt) {
            if (_index >= 0) {
              this.other_tags.splice(_index, 1)

              if (this.other_tags.length === 0) {
                this.concordanceList.other = false
              }
            }
          } else {
            if (_index < 0) {
              this.other_tags.push(pathology)
              this.concordanceList.other = true
            }
          }
        }

        this.updatedPathology++
      }
    },
    getPatientSubmissions: async function () {
      if (this.submission && this.submission.patient && this.submission.patient._id) {
        this.otherSubmissions = await staffSubmissionService.getForPatient(this.submission.patient._id)
        this.updatedOthers++
      }
    },
    formatDate: function (date) {
      return moment(date).format('D MMM YYYY, h:mm a')
    },
    formatDateNoTime: function (date) {
      return moment(date).format('D MMM YYYY')
    },
    toggle: function (slidein) {
      this.open[slidein] = !this.open[slidein]
    },
    setHeatmap: function (name) {
      this.selectedHeatmap = name
    },
    getConclusion: function (name) {
      if (this.model === 'General') {
        return this.getGeneralConclusion(name)
      } else if (this.model === 'Tuberculosis') {
        return this.getTuberculosisConclusion(name)
      } else if (this.model === 'Pneumonia') {
        return this.getPneumoniaConclusion(name)
      } else {
        return this.getGeneralConclusion(name)
      }
    },
    getGeneralConclusion: function (name) {
      return 'Abnormality score: ' + (this.current.analysis.score).toFixed(2)
    },
    getTuberculosisConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return 'High Probability of Tuberculosis'
      } else if (name.indexOf('intermediate') > -1) {
        return 'Intermediate Probability of Tuberculosis'
      } else if (name.indexOf('low') > -1) {
        return 'Low Probability of Tuberculosis'
      } else if (name.indexOf('other') > -1) {
        return 'Other X-Rays'
      } else {
        return 'No Pathologies Found'
      }
    },
    getPneumoniaConclusion: function (name) {
      if (name.indexOf('high') > -1) {
        return 'High Probability of Pneumonia'
      } else if (name.indexOf('intermediate') > -1) {
        return 'Intermediate Probability of Pneumonia'
      } else if (name.indexOf('low') > -1) {
        return 'Low Probability of Pneumonia'
      } else if (name.indexOf('other') > -1) {
        return 'Other X-Rays'
      } else {
        return 'No Pathologies Found'
      }
    },
    getIcon: function (name) {
      if (name.indexOf('high') > -1) {
        return 'exclamation-triangle'
      } else if (name.indexOf('intermediate') > -1 || name.indexOf('low') > -1) {
        return 'exclamation-triangle'
      } else {
        return 'heartbeat'
      }
    },
    getIndicator: function (name) {
      if (name.indexOf('high') > -1) {
        return 'high' + ' ' + this.model.toLowerCase()
      } else if (name.indexOf('intermediate') > -1) {
        return 'moderate' + ' ' + this.model.toLowerCase()
      } else if (name.indexOf('low') > -1) {
        return 'moderate' + ' ' + this.model.toLowerCase()
      } else {
        return 'nothing' + ' ' + this.model.toLowerCase()
      }
    },
    originalImage: function (result) {
      if (result) {
        if (result.attachments[0].path.indexOf('http') === -1) {
          return apiRoute() + result.attachments[0].path
        } else {
          return result.attachments[0].path
        }
      } else {
        return null
      }
    },
    boundingBoxes: function (result) {
      if (result) {
        if (result.attachments[0].response.bounding.path.indexOf('http') === -1) {
          return apiRoute() + result.attachments[0].response.bounding.path
        } else {
          return result.attachments[0].response.bounding.path
        }
      } else {
        return null
      }
    },
    hasCardiomegaly: function (result) {
      if (result) {
        const _pathology = result.attachments[0].response.labels[0].pathologies.find(pathology => pathology.predicted_diagnosis === 'Cardio')
        if (_pathology) {
          return _pathology.confidence
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isShown: function (_id) {
      if (this.shownList && this.shownList[_id] !== undefined) {
        return this.shownList[_id]
      } else {
        return false
      }
    },
    isConcordance: function (_id) {
      if (this.concordanceList && this.concordanceList[_id] !== undefined) {
        return this.concordanceList[_id]
      } else {
        return false
      }
    },
    toggleShown: function (_id) {
      if (this.shownList[_id] === true) {
        this.$set(this.shownList, _id, false)
      } else {
        this.$set(this.shownList, _id, true)
      }

      this.updated++
    },
    toggleConcordance: function (_id) {
      if (this.concordanceList[_id] === true) {
        this.$set(this.concordanceList, _id, false)
        this.$set(this.shownList, _id, false)
        if (_id === 'other') {
          this.other_tags = []
        }
      } else {
        this.$set(this.concordanceList, _id, true)
        this.$set(this.shownList, _id, true)
      }

      this.updated++
    },
    toggleAll: function (value) {
      for (const _prop in this.shownList) {
        if (this.concordanceList[_prop] === true || _prop === 'ctr') {
          this.$set(this.shownList, _prop, value)
        }
      }

      this.updated++
    },
    toggleConcordanceAll: function (value) {
      for (const _prop in this.concordanceList) {
        this.$set(this.concordanceList, _prop, value)
      }

      this.updated++
    },
    setDimensions: function (evt) {
      const _image = evt.target
      this.dimensions.w = _image.clientWidth
      this.dimensions.h = _image.clientHeight
      this.dimensions.ratio = _image.clientWidth / _image.naturalWidth
      this.loaded = true
    },
    exitScreen: function () {
      var _changed = false
      let _diagnosis = []

      if (this.current.submission.attachments[0].feedback) {
        const _feedback = this.current.submission.attachments[0].feedback

        _feedback.labels[0].pathologies.forEach(_pathology => {
          console.log(_pathology.concordance, this.concordanceList[_pathology.pathology_id])
          console.log(_pathology.concordance !== this.concordanceList[_pathology.pathology_id])
          if (_pathology.concordance !== this.concordanceList[_pathology.pathology_id]) {
            _changed = true
          }
        })

        if (!this.other_tags.equals(_feedback.other)) {
          _changed = true
        }

        if (this.diagnosisAgree) {
          if (_feedback.diagnosis.indexOf(this.current.analysis.conclusion) < 0) {
            _changed = true
            _diagnosis = _feedback.diagnosis
            _diagnosis.push(this.current.analysis.conclusion)
          }
        } else {
          if (_feedback.diagnosis.indexOf(this.current.analysis.conclusion) >= 0) {
            _changed = true
            _feedback.diagnosis.forEach(__diagnosis => {
              if (__diagnosis.toLowerCase() !== this.current.analysis.conclusion.toLowerCase()) {
                _diagnosis.push(__diagnosis)
              }
            })
          }
        }
      } else {
        if (this.diagnosisAgree) {
          _diagnosis.push(this.current.analysis.conclusion)
        }
        _changed = true
      }

      if (_changed) {
        console.log('Saving feedback')
        const _pathologies = []
        for (const _prop in this.concordanceList) {
          if (_prop !== 'other' && _prop.indexOf('new_') < 0) {
            _pathologies.push({
              id: _prop,
              value: this.concordanceList[_prop]
            })
          }
        }

        this.saveFeedback({
          id: this.current.submission.attachments[0].response._id,
          pathologies: _pathologies,
          diagnosis: _diagnosis,
          other: this.other_tags,
          submission: this.current.submission._id
        })
      }

      if (this.auditChanged) {
        const _pathologies = []
        let _responseId = null

        if (this.current.submission && this.current.submission.attachments && this.current.submission.attachments.length > 0) {
          const attachment = this.current.submission.attachments[0]
          if (attachment.response && attachment.response.labels && attachment.response.labels.length > 0) {
            _responseId = attachment.response._id
            const label = attachment.response.labels[0]
            if (label.pathologies && label.pathologies.length > 0) {
              label.pathologies.forEach(_pathology => {
                if (_pathology.predicted_diagnosis !== 'Thoracic' && _pathology.predicted_diagnosis !== 'Cardio') {
                  if (this.isConcordance(_pathology._id) === true) {
                    const _updated = this.filteredList.find(__pathology => __pathology._id === _pathology._id)
                    _pathologies.push(_updated)
                  }
                } else {
                  _pathologies.push(_pathology)
                }
              })
            }
          }
        }

        if (this.other_coords && this.other_coords.length > 0) {
          this.other_coords.forEach(_pathology => {
            _pathologies.push({
              bounding_box: _pathology.coords,
              comment: null,
              confidence: null,
              predicted_diagnosis: _pathology.name === 'Other' ? _pathology.other : _pathology.name.replace(/[^A-Z0-9]+/ig, '').toLowerCase()
            })
          })
        }

        const _params = {
          submission: this.current.submission._id,
          id: _responseId,
          labels: [{
            pathologies: _pathologies,
            reasons: null
          }],
          tags: []
        }

        this.updateLabels(_params)
      }

      this.$nextTick(() => {
        this.$emit('close-analysis')
      })
    },
    justExitScreen: function () {
      this.$emit('close-analysis')
    },
    getNewReport: function () {
      this.newReport = Object.assign({}, this.report)
      this.newReport.baseline = this.report.baseline === 'Yes'
      if (this.newReport.parenchyma && this.newReport.parenchyma.length > 0) {
        const _temp = []
        this.newReport.parenchyma.filter(_pathology => _pathology.rt || _pathology.lt).forEach(_pathology => {
          _temp.push({
            name: this.$options.filters.formatPathologyName(_pathology.name, this.options),
            present: _pathology.rt || _pathology.lt,
            areaInvolved: {
              rul: _pathology.ruz,
              rml: _pathology.rmz,
              rll: _pathology.rlz,
              lul: _pathology.luz,
              ling: _pathology.lmz,
              lll: _pathology.llz,
              right: _pathology.rt,
              left: _pathology.lt
            }
          })
        })

        this.newReport.parenchyma = _temp
      }
    },
    submitAndComplete: function () {
      this.report.status = 'Completed'
      this.report.submission = this.submission._id
      this.getNewReport()
      console.log(this.newReport)
      this.saveReport(this.newReport)
    },
    submit: function () {
      this.report.submission = this.submission._id
      this.getNewReport()
      console.log(this.newReport)
      this.saveReport(this.newReport)
    },
    emailReport: async function () {
      const _response = await staffReportService.email(this._report.info._id, this.emailTo)
      console.log(_response)
    },
    downloadPdf: function () {
      if (this._report.info.attachment.path.indexOf('http') === -1) {
        window.open(apiRoute() + '/image/report/' + this._report.info._id, '_blank')
      } else {
        window.open(this._report.info.attachment.path, '_blank')
      }
    },
    generatePdf: function () {
      staffReportService.generatePdf(this._report.info._id)
        .then(
          () => {
            this.$router.go()
          },
          error => {
            console.log(error)
          })
    },
    sendToLocal: async function () {
      staffReportService.sendToLocal(this._report.info._id)
        .then(
          () => {
            this.$router.go()
          },
          error => {
            console.log(error)
          })
    },
    sendToRemote: async function () {
      staffReportService.sendToRemote(this._report.info._id)
        .then(
          () => {
            this.$router.go()
          },
          error => {
            console.log(error)
          })
    },
    toggleSmallOpacitiesZone (zone) {
      const index = this.ilo.smallOpacitiesZones.indexOf(zone)
      if (index === -1) {
        this.ilo.smallOpacitiesZones.push(zone)
      } else {
        this.ilo.smallOpacitiesZones.splice(index, 1)
      }
    },
    toggleSymbol (symbol) {
      const index = this.ilo.symbolValues.indexOf(symbol)
      if (index === -1) {
        this.ilo.symbolValues.push(symbol)
      } else {
        this.ilo.symbolValues.splice(index, 1)
      }
    },
    toggleSite (site, column, symbol, forceRemove = false) {
      const index = this.ilo.pleuralPlaques[site][column].indexOf(symbol)
      if (index === -1) {
        if (!forceRemove) {
          this.ilo.pleuralPlaques[site][column].push(symbol)
        }
      } else {
        this.ilo.pleuralPlaques[site][column].splice(index, 1)
      }

      if (!forceRemove) {
        if (symbol === '0') {
          this.toggleSite(site, column, 'R', true)
          this.toggleSite(site, column, 'L', true)
        } else {
          this.toggleSite(site, column, '0', true)
        }
      }
    },
    toggleCostophrenic (symbol, forceRemove = false) {
      const index = this.ilo.costophrenic.indexOf(symbol)
      if (index === -1) {
        if (!forceRemove) {
          this.ilo.costophrenic.push(symbol)
        }
      } else {
        this.ilo.costophrenic.splice(index, 1)
      }

      if (!forceRemove) {
        if (symbol === '0') {
          this.toggleCostophrenic('R', true)
          this.toggleCostophrenic('L', true)
        } else {
          this.toggleCostophrenic('0', true)
        }
      }
    },
    toggleDifffuseSite (site, column, symbol, forceRemove = false) {
      const index = this.ilo.diffusePleural[site][column].indexOf(symbol)
      if (index === -1) {
        if (!forceRemove) {
          this.ilo.diffusePleural[site][column].push(symbol)
        }
      } else {
        this.ilo.diffusePleural[site][column].splice(index, 1)
      }

      if (!forceRemove) {
        if (symbol === '0') {
          this.toggleDifffuseSite(site, column, 'R', true)
          this.toggleDifffuseSite(site, column, 'L', true)
        } else {
          this.toggleDifffuseSite(site, column, '0', true)
        }
      }
    },
    // downloadIloPdf () {
    //   window.open('/downloads/ilo-reading-template.pdf', '_blank')
    // },
    async downloadIloPdf () {
      const submissionId = this.current.submission._id
      try {
        console.log('dl')
        // Show loading indicator if you have one
        this.isDownloading = true
        const token = this.auth.user.token
        // Make request to your API endpoint
        const response = await fetch(apiRoute() + `/api/v1/staff/reports/ilo_download/${submissionId}`, {
          method: 'GET',
          headers: {
            // Include any auth headers if needed
            Authorization: `Bearer ${token}`,
            Accept: 'application/pdf'
          }
        })
        if (!response.ok) {
        }
        // Get the filename from the Content-Disposition header if available
        const contentDisposition = response.headers.get('Content-Disposition')
        let filename = 'ilo-reading-sheet.pdf' // default filename
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?([^"]*)"?/)
          if (filenameMatch && filenameMatch[1]) {
            filename = filenameMatch[1]
          }
        }
        // Convert response to blo
        const blob = await response.blob()
        // Create download link and trigger download
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = filename
        document.body.appendChild(link)
        link.click()
        // Cleanu
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
      } catch (error) {
        console.error('Error downloading PDF:', error)
        // Show error message to user
      } finally {
        this.isDownloading = false
      }
    }
  },
  watch: {
    iloLoading (_new, _old) {
      console.log('incoming ilo', _new, this._report.ilo.report)
      if (!_new && this._report.ilo.report !== null) {
        this.ilo = this._report.ilo.report
      }
    },
    reportSaveState (_new, _old) {
      if (_new && this.alert.message == null && this.alert.type == null) {
        this.exitScreen()
      }
    },
    reportLoadState (_new, _old) {
      console.log(_new, 'Report received')
      if (_new && !this._report.status.notFound && this.alert.message == null && this.alert.type == null) {
        console.log('I am here')
        this.report.status = this._report.info.status
        this.report.created_at = this._report.info.created_at
        this.report.created_by = this._report.info.created_by.personal.fullname
        this.report.baseline = this._report.info.baseline ? 'Yes' : 'No'
        this.report.resultType = this._report.info.resultType
        this.report.technical = this._report.info.technical
        this.report.findings = this._report.info.findings
        this.report.reporter = this._report.info.reporter
        this.report.boneAndSoftTissue = this._report.info.boneAndSoftTissue
        this.report.conclusion = this._report.info.conclusion
        if (this._report.info.parenchyma && this._report.info.parenchyma.length > 0) {
          this.report.parenchyma = []
          this._report.info.parenchyma.forEach(_pathology => {
            this.report.parenchyma.push({
              name: _pathology.name.replace(/[^a-zA-Z]/g, '').toLowerCase(),
              ruz: _pathology.areaInvolved.rul,
              rmz: _pathology.areaInvolved.rml,
              rlz: _pathology.areaInvolved.rll,
              luz: _pathology.areaInvolved.lul,
              lmz: _pathology.areaInvolved.ling,
              llz: _pathology.areaInvolved.lll,
              rt: _pathology.areaInvolved.right,
              lt: _pathology.areaInvolved.left
            })
          })
        }
      } else {
        this.report.findings = {
          lines: 'None',
          lungs: 'The lungs are well inflated and clear. There is no evidence of pneumonia or pulmonary oedema.',
          pleura: 'There is no pleural effusion or pneumothorax.',
          heart: 'The cardiomediastinal silhouette is normal.',
          bones: 'The visualised skeleton is normal'
        }
        this.report.conclusion = 'The Lungs are clear with no evidence of pneumonia or active pulmonary tuberculosis.'
      }
    },
    'dimensions.w': function (_new, _old) {
      if (this.dimensions.ratio >= 1 || this.labeller.width > (this.dimensions.w + 20)) {
        this.labellerKey += 1

        setTimeout(() => {
          this.boundedImageKey += 1
          this.$refs.cropper.clear()
          this.$refs.cropper.reset()
          this.$refs.cropper.enable()
          this.$refs.cropper.initCrop()
          this.$refs.cropper.replace(this.selectedImage)
          this.$refs.cropper.cropper.disabled = true
          this.$refs.cropper.disable()
        }, 500)
      }
    },
    activeImage (_state) {
      if (_state !== 'current') {
        this.open.feedback = false
      }
    }
  }
}
</script>

<style>
.float-alert {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 10000;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
</style>
